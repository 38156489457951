import { useFormContext, UseFormProps } from "react-hook-form";
import {
  Form,
  withFormContext,
  OverlayLoader,
  DateControl,
} from "../../common";
import { MarketRoleStep, SigneeStep } from "../steps";
import { SubmitHandler } from "react-hook-form";
import { useMutationWrapper } from "../../../hooks";
import {
  edsn_Marktrol,
  MprService,
  RegistrationCancelDto,
} from "../../../openapi/edsn-webapi";
import { Alert, Button, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { safePropertyMap } from "../../../utils";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Ean13SelectControl from "../Ean13SelectControl";

const RegistrationCancelForm = () => {
  const map = safePropertyMap<RegistrationCancelDto>();
  const { watch } = useFormContext<RegistrationCancelDto>();
  const marketRole = watch("marketRole");
  const intl = useIntl();
  const showLV = marketRole === edsn_Marktrol.LVLEVERANCIER;
  const showGeneric = !showLV && !!marketRole;
  const navigate = useNavigate();
  const navigateToSuccess = () => navigate("/registratieformulier/success");
  const { mutate, isLoading: isLoadingSubmit } = useMutationWrapper(
    ["submitMprCancel"],
    (requestBody: RegistrationCancelDto) =>
      MprService.postApiMprCancel({ requestBody }),
    { onSuccess: navigateToSuccess }
  );

  const onSubmit: SubmitHandler<RegistrationCancelDto> = (data) => {
    mutate(data);
  };

  return (
    <Box>
      <Form onSubmit={onSubmit}>
        <Typography variant="h6" color="secondary">
          <FormattedMessage id="companyDetails" />
        </Typography>
        <MarketRoleStep />
        {showLV && (
          <Alert severity="info" sx={{ mb: 1 }}>
            <FormattedMessage id="tooltipCancelLv" />
          </Alert>
        )}
        {showGeneric && (
          <Alert severity="info" sx={{ mb: 1 }}>
            <FormattedMessage
              id="tooltipCancelGeneric"
              values={{
                marketRole: intl.formatMessage({
                  id: `edsn_Marktrol.${marketRole}`,
                }),
              }}
            />
          </Alert>
        )}
        <Ean13SelectControl />
        <Typography variant="h6" color="secondary">
          <FormattedMessage id="cancelDate" />
        </Typography>
        <DateControl name={map("endDate")} label="endDateCancel" required />
        <Typography variant="h6" color="secondary">
          <FormattedMessage id="signeeDetails" />
        </Typography>
        <SigneeStep />
        <Button variant="contained" type="submit" sx={{ mt: 1, mr: 1 }}>
          <FormattedMessage id="submit" />
        </Button>
        {isLoadingSubmit && <OverlayLoader />}
      </Form>
    </Box>
  );
};

export default withFormContext<
  UseFormProps<RegistrationCancelDto>,
  RegistrationCancelDto
>(RegistrationCancelForm);
