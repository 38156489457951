/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum edsn_Marktrol {
  LVLEVERANCIER = "LVLeverancier",
  MVMEETVERANTWOORDELIJKE = "MVMeetverantwoordelijke",
  PVPROGRAMMAVERANTWOORDELIJKE = "PVProgrammaverantwoordelijke",
  ODAOVERIGE_DIENSTENAANBIEDER = "ODAOverigeDienstenaanbieder",
  RNBREGIONALE_NETBEHEERDER = "RNBRegionaleNetbeheerder",
  GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM = "GDSeigenaarGeslotenDistributieSysteem",
  EPVENERGIE_PRIJSVERGELIJKER = "EPVEnergiePrijsvergelijker",
  CSPCONGESTIE_SERVICE_PROVIDER = "CSPCongestieServiceProvider",
  LNBLANDELIJK_NETBEHEERDER = "LNBLandelijkNetbeheerder",
  BSPBALANCING_SERVICE_PROVIDER = "BSPBalancingServiceProvider",
}
