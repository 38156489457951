import {
  MutationFunction,
  MutationKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useToaster } from "../components/common/Toaster";
import { ApiError } from "../openapi/edsn-webapi";

type ExtendedMutationOptions = {
  toastOnError?: boolean;
};

const defaultOptions: ExtendedMutationOptions = {
  toastOnError: true,
};

const useMutationWrapper = <
  TData = unknown,
  TVariables = void,
  TContext = unknown
>(
  key: MutationKey,
  func?: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, ApiError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  > &
    ExtendedMutationOptions
): UseMutationResult<TData, ApiError, TVariables, TContext> => {
  const { toastOnError } = { ...defaultOptions, ...(options || {}) };
  const mutation = useMutation(key, func, options);
  const addToast = useToaster((state) => state.addToast);

  if (mutation.isError) {
    if (mutation.error.status == 401) {
      console.log("Reloading window to force a new login");
      window.location.reload();
    } else if (toastOnError) {
      addToast({ type: "error", message: mutation.error.message });
    }
  }

  return mutation;
};

export default useMutationWrapper;
