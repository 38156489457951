import { Alert, Button, Typography } from "@mui/material";
import { InputControl, OverlayLoader } from "../common";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MprService,
  PreferredPartyEanValidationDto,
  PreferredPartyEanValidationResponsDto,
  RegistrationCreateDto,
  edsn_Marktrol,
} from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { ValidateResult, useFormContext } from "react-hook-form";
import CheckboxControl from "../common/Form/CheckboxControl";
import { ean13Validator } from "../../validators";
import useRegistration from "./useRegistration";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { useMutationWrapper } from "../../hooks";
import { useState } from "react";

type PreferredPartyElectricityControlProps = {
  showLvTooltip?: boolean;
  required?: boolean;
};

const PreferredPartyElectricityControl = ({
  showLvTooltip,
  required,
}: PreferredPartyElectricityControlProps) => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const { watch, getValues } = useFormContext<RegistrationCreateDto>();
  const intl = useIntl();
  const marketRole = watch("marketRole")!;

  const marketRoleToCheck =
    marketRole === edsn_Marktrol.LVLEVERANCIER
      ? edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE
      : edsn_Marktrol.LVLEVERANCIER;

  const showPvActingAsLvEan =
    marketRole === edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE;

  const [preferredPartyElectricityState, setPreferredPartyElectricityState] =
    useState<PreferredPartyEanValidationResponsDto>();

  const [
    preferredPartySupplierElectricityState,
    setPreferredPartySupplierElectricityState,
  ] = useState<PreferredPartyEanValidationResponsDto>();

  const { mutateAsync, isLoading } = useMutationWrapper(
    ["postApiMprValidatePreferredParty"],
    (requestBody: PreferredPartyEanValidationDto) =>
      MprService.postApiMprValidatePreferredParty({ requestBody })
  );

  const setPermissionDocumentelectricity = useRegistration(
    (state) => state.setPermissionDocumentelectricity
  );
  const permissionDocumentElectricity = useRegistration(
    (state) => state.permissionDocumentElectricity
  );

  const isUpdate = useRegistration((state) => state.isUpdate);

  const validatePermissonsDocumentElectricity = (
    val: string
  ): ValidateResult => {
    if (!required) {
      var hasEan = !!getValues().preferredPartyElectricityEan;
      if (!hasEan) {
        return true;
      }
      if (!val) {
        return "requiredMessage";
      }
    }
    return !!permissionDocumentElectricity || isUpdate || "fileRequired";
  };
  const validateEan = async (
    ean13Code: string,
    setter: (result: PreferredPartyEanValidationResponsDto) => void
  ) => {
    if (!ean13Code && !required) {
      return true;
    }

    const validEan = ean13Validator(ean13Code);

    if (!validEan) {
      setter({ isValid: false, name: "__invalid__" });
      return "invalidEan";
    }

    const result = await mutateAsync({
      ean13Code,
      marketRole: marketRoleToCheck,
    });
    setter(result);

    if (!result.isValid) {
      return "preferredPartyEanNotFound";
    }

    return true;
  };

  return (
    <>
      <Typography variant="h6" color="secondary">
        <FormattedMessage id="preferredPartyElectricity" />
      </Typography>
      {showLvTooltip && (
        <Alert severity="info">
          <FormattedMessage id="preferredPartLvInfoMessage" />
        </Alert>
      )}
      <InputControl
        maxLength={13}
        name={map("preferredPartyElectricityEan")}
        label="preferredPartyElectricityEan"
        required={required}
        helperText={
          preferredPartyElectricityState
            ? preferredPartyElectricityState.name === "__invalid__"
              ? intl.formatMessage({ id: "invalidEan" })
              : preferredPartyElectricityState.isValid
              ? preferredPartyElectricityState.name!
              : intl.formatMessage({ id: "preferredPartyEanNotFound" })
            : undefined
        }
        endAdornment={isLoading ? <OverlayLoader /> : undefined}
        validate={(ean13Code: string) =>
          validateEan(ean13Code, setPreferredPartyElectricityState)
        }
        onBlur={(e) =>
          validateEan(e.target.value, setPreferredPartyElectricityState)
        }
      />

      <CheckboxControl
        name={map("preferredPartyElectricContractIsSigned")}
        label="preferredPartyContractIsSigned"
        required={required}
        validate={validatePermissonsDocumentElectricity}
      />
      <Button
        sx={{ width: "fit-content", mt: 1, mb: 2 }}
        variant="outlined"
        startIcon={<CloudUpload />}
        component="label"
      >
        {permissionDocumentElectricity ? (
          permissionDocumentElectricity?.name
        ) : (
          <FormattedMessage id="uploadFile" />
        )}
        <input
          onChange={(event) =>
            setPermissionDocumentelectricity(
              event.target.files?.length ? event.target.files[0] : undefined
            )
          }
          type="file"
          hidden
        />
      </Button>
      {showPvActingAsLvEan && (
        <InputControl
          maxLength={13}
          name={map("pvPreferredPartyElectricityLvEan")}
          label="lvEan"
          required={required}
          helperText={
            preferredPartySupplierElectricityState
              ? preferredPartySupplierElectricityState.name === "__invalid__"
                ? intl.formatMessage({ id: "invalidEan" })
                : preferredPartySupplierElectricityState.isValid
                ? preferredPartySupplierElectricityState.name!
                : intl.formatMessage({ id: "preferredPartyEanNotFound" })
              : undefined
          }
          endAdornment={isLoading ? <OverlayLoader /> : undefined}
          validate={(ean13Code: string) =>
            validateEan(ean13Code, setPreferredPartySupplierElectricityState)
          }
          onBlur={(e) =>
            validateEan(
              e.target.value,
              setPreferredPartySupplierElectricityState
            )
          }
        />
      )}
    </>
  );
};

export default PreferredPartyElectricityControl;
