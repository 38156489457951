import { useRef } from "react";
import { FormStepper } from "../common";
import FinalStep from "./FinalStep";
import ConnectionDetailStep from "./ConnectionDetailStep";
import ContactPersonStep from "./ContactPersonStep";
import ConnectionInfoStep from "./ConnectionInfoStep";

const CongestionInterestStepper = () => {
  const steps = useRef([
    {
      label: "connectionInformation",
      Component: () => <ConnectionInfoStep />,
    },
    { label: "yourConnection", Component: () => <ConnectionDetailStep /> },
    {
      label: "contactDetails",
      Component: () => <ContactPersonStep />,
    },
    { label: "submit", Component: () => <FinalStep /> },
  ]);
  return <FormStepper steps={steps.current} />;
};

export default CongestionInterestStepper;
