const ean18Validator = (value: string) => {
  if (!value) {
    return true;
  }

  if (value?.length !== 18) {
    return "incorrectEan18";
  }

  const isValid =
    value
      .split("")
      .reduce((res, cur, i) => res + +cur * (i % 2 === 0 ? 3 : 1), 0) %
      10 ===
    0;

  return isValid ? isValid : "incorrectEan18";
};

export default ean18Validator;
