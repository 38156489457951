/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AdjustablePower {
    STORAGE = 'Storage',
    SOLAR_PARK = 'SolarPark',
    WINDMILLS = 'Windmills',
    WKK = 'Wkk',
    FLEXIBLE_PRODUCTION_PROCESS = 'FlexibleProductionProcess',
    EBOILER = 'Eboiler',
    OTHER = 'Other',
}
