import { SelectControl } from "../common";
import { FormattedMessage, useIntl } from "react-intl";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../openapi/edsn-webapi";
import { Alert, Link, SelectChangeEvent } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Language, useLocalizationStore } from "../Localization";

type MarketroleSelectControlProps = {
  label?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (
    onChange: (...event: any[]) => void
  ) => (e: SelectChangeEvent<any>, child: React.ReactNode) => void;
};

const MarketroleSelectControl = ({
  label = "marketRole",
  name,
  required,
  disabled,
  onChange,
}: MarketroleSelectControlProps) => {
  const intl = useIntl();
  const { watch } = useFormContext<RegistrationCreateDto>();
  const marketRole = watch("marketRole");
  const isOda = marketRole === edsn_Marktrol.ODAOVERIGE_DIENSTENAANBIEDER;
  const language = useLocalizationStore((state) => state.language);
  const url = language === Language.DUTCH ? "faq-oda" : "faq-oda-en";

  const options = Object.values(edsn_Marktrol)
    .filter(
      (value) =>
        value !== edsn_Marktrol.RNBREGIONALE_NETBEHEERDER &&
        value !== edsn_Marktrol.LNBLANDELIJK_NETBEHEERDER
    )
    .map((value) => ({
      value,
      label: intl.formatMessage({ id: `edsn_Marktrol.${value}` }),
    }));

  return (
    <>
      <SelectControl
        name={name}
        label={label}
        options={options}
        required={required}
        disabled={disabled}
        onChange={onChange}
      />
      {isOda && (
        <Alert severity="info" sx={{ mb: 1 }}>
          <FormattedMessage
            id="odaFaqInformation"
            values={{
              link: (
                <Link
                  variant="subtitle2"
                  underline="hover"
                  href={`${process.env.REACT_APP_MIJN_PIE_BASE_URL}/${url}`}
                  target="_blank"
                >
                  <FormattedMessage id="odaFaqLinkText" />
                </Link>
              ),
            }}
          />
        </Alert>
      )}
    </>
  );
};

export default MarketroleSelectControl;
