import { Grid, Link, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PageBody, PageHeader } from "../../common";

const RegistrationSuccessPage = () => {
  return (
    <>
      <PageHeader maxWidth="md" title="registrationSuccessTitle" />
      <PageBody maxWidth="md">
        <Grid container direction="column">
          <Typography paragraph variant="body1">
            <FormattedMessage id="registrationSuccessMessage" />
          </Typography>
          <Typography paragraph variant="body1">
            <FormattedMessage id="kindRegards" />
          </Typography>
          <Typography paragraph variant="body1">
            EDSN B.V.
          </Typography>
          <Link href="/registratieformulier" variant="body1" underline="hover">
            <FormattedMessage id="backToMainMenu" />
          </Link>
        </Grid>
      </PageBody>
    </>
  );
};

export default RegistrationSuccessPage;
