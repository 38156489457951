import { PageHeader, PageBody, OverlayLoader } from "../../common";
import RegistrationChangeForm from "./RegistrationChangeForm";
import { useSearchParams } from "react-router-dom";
import { useMutationWrapper } from "../../../hooks";
import { MprService } from "../../../openapi/edsn-webapi";
import { useEffect } from "react";

const RegistrationFormPage = () => {
  const [searchParams, _] = useSearchParams();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const { mutate, isLoading, isIdle, data } = useMutationWrapper(
    ["getApiMpr"],
    ({ id, token }: { id: string; token: string }) => {
      return MprService.getApiMprChange({ id, token });
    }
  );

  const showLoader = !!id && !!token && (isIdle || isLoading);

  useEffect(() => {
    if (id && token) {
      mutate({ id, token });
    }
  }, [id, token, mutate]);
  return (
    <>
      <PageHeader maxWidth="md" title="changeRegistrationTitle" />
      <PageBody maxWidth="md">
      {showLoader ? (
          <OverlayLoader />
        ) : (
          <RegistrationChangeForm defaultValues={data} id={id} token={token} />
        )}
      </PageBody>
    </>
  );
};

export default RegistrationFormPage;
