import { PageBody, PageHeader } from "../common";
import CongestionInterestForm from "./CongestionInterestForm";

const CongestionInterestPage = () => {
  return (
    <>
      <PageHeader
        maxWidth="md"
        title="congestionInterest"
        navigateBackUrl={process.env.REACT_APP_PIE_BASE_URL}
        navigateBackLabel="navigateBackToMainPage"
      />
      <PageBody maxWidth="md">
        <CongestionInterestForm defaultValues={{ connections: [{}] }} />
      </PageBody>
    </>
  );
};

export default CongestionInterestPage;
