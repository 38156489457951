import create from "zustand";

const AuthStateSessionKey = "fr_auth";

type AuthStoreState = {
  isAuthenticated: () => boolean;
  access_token?: string;
  exp?: Date;
  setAuthState: (authState: AuthState) => void;
  signout: () => void;
};

export interface AuthState {
  access_token: string;
  exp: Date;
}

const getAuthState = (): AuthState | null => {
  const authStateJson = sessionStorage.getItem(AuthStateSessionKey);
  if (!authStateJson) {
    return null;
  }
  const authState = JSON.parse(authStateJson) as AuthState;
  authState.exp = new Date(authState.exp);
  return authState;
};

const useAuthStore = create<AuthStoreState>((set, get) => ({
  access_token: getAuthState()?.access_token,
  exp: getAuthState()?.exp,
  isAuthenticated: () => {
    if (!get().access_token && !get().exp) {
      return false;
    }

    return new Date(new Date().getTime() + 30000) < get().exp!;
  },
  setAuthState: (authState: AuthState) => {
    set(() => ({
      access_token: authState.access_token,
      exp: authState.exp,
    }));
    sessionStorage.setItem(AuthStateSessionKey, JSON.stringify(authState));
  },
  signout: () => {
    sessionStorage.removeItem(AuthStateSessionKey);
    window.location.href = `${process.env.REACT_APP_EDSN_WEBAPI_BASE_URL}/signout`;
  },
}));

export default useAuthStore;
