import { FC, useEffect } from "react";

interface LoginProps {
  redirect: string;
  state?: string;
}

const Login: FC<LoginProps> = ({ redirect, state }) => {
  useEffect(() => {
    const stateParam = state ? `&state=${state}` : "";
    window.location.href = `${process.env.REACT_APP_EDSN_WEBAPI_BASE_URL}/token?redirect_uri=${redirect}${stateParam}`;
  }, []);
  return <div>Je wordt doorgestuurd om in te loggen met e-herkenning</div>;
};

export default Login;
