import { useFormContext } from "react-hook-form";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../../openapi/edsn-webapi";
import ContactControl from "../ContactControl";
import OptionalContactControl from "../OptionalContactControl";

type contactDetailStepProps = {
  isCreate?: boolean;
};

const ContactDetailStep = ({ isCreate }: contactDetailStepProps) => {
  const { watch } = useFormContext<RegistrationCreateDto>();
  const isLv = watch("marketRole") === edsn_Marktrol.LVLEVERANCIER;
  return (
    <>
      <ContactControl fieldname="contactDefault" />
      {isLv && isCreate && (
        <>
          <OptionalContactControl
            nameBool="contactProcessTransferDifferentThanDefault"
            name="contactProcessTransfer"
            label="contactProcessTransferTitle"
          />
          <OptionalContactControl
            nameBool="contactAtoDifferentThanDefault"
            name="contactAto"
            label="contactAtoTitle"
          />
          <OptionalContactControl
            nameBool="contactPeriodicNetworkRatesDifferentThanDefault"
            name="contactPeriodicNetworkRates"
            label="contactPeriodicNetworkRatesTitle"
          />
          <OptionalContactControl
            nameBool="contactTocDifferentThanDefault"
            name="contactToc"
            label="contactTocTitle"
          />
        </>
      )}
    </>
  );
};

export default ContactDetailStep;
