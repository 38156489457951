import { IntlProvider } from "react-intl";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider as DatePickerLocaleProvider } from "@mui/x-date-pickers";
import useLocalizationStore, { localeDataMap } from "./useLocalizationStore";

type LocalizationProviderProps = {
  children: React.ReactNode;
};

const LocalizationProvider = ({ children }: LocalizationProviderProps) => {
  const language = useLocalizationStore((state) => state.language);
  return (
    <IntlProvider locale={language} messages={localeDataMap[language].messages}>
      <DatePickerLocaleProvider dateAdapter={AdapterMoment}>
        {children}
      </DatePickerLocaleProvider>
    </IntlProvider>
  );
};

export default LocalizationProvider;
