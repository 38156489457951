import { SelectOption } from "../openapi/edsn-webapi";

export const getSelectOptions = (options: Array<SelectOption> | null | undefined) =>
{
    if(!options) {
        return [];
    }

    return options.map(m => ({
        value: m.value ?? "",
        label: m.label ?? ""
    }));
}