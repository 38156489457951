import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { FormattedMessage } from "react-intl";
import useLocalizationStore, {
  Language,
  localeDataMap,
} from "./useLocalizationStore";

// see https://flagicons.lipis.dev/ for all flags
const LanguageSelect = (props: SelectProps<Language>) => {
  const language = useLocalizationStore((state) => state.language);
  const setLanguage = useLocalizationStore((state) => state.setLanguage);

  const onChange = (e: SelectChangeEvent<Language>) => {
    setLanguage(e.target.value as Language);
  };

  return (
    <Select
      value={language}
      variant="standard"
      onChange={onChange}
      renderValue={(value) => (
        <ReactCountryFlag
          style={{
            width: "2rem",
            height: "2rem",
          }}
          countryCode={localeDataMap[value].flagCode}
          svg
        />
      )}
      {...props}
    >
      {Object.values(Language).map((value) => (
        <MenuItem key={value} value={value}>
          <>
            <Box sx={{ mr: 2 }}>
              <ReactCountryFlag
                countryCode={localeDataMap[value].flagCode}
                svg
              />
            </Box>
            <FormattedMessage id={`language.${value}`} />
          </>
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelect;
