import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { ApiError } from "../openapi/edsn-webapi";

type ExtendedQueryOptions = {
  toastOnError?: boolean;
};

const defaultOptions: ExtendedQueryOptions = {
  toastOnError: true,
};

const useQueryWrapper = <T>(
  key: QueryKey,
  func: () => T | Promise<T>,
  options?: Omit<UseQueryOptions<T, ApiError>, "queryKey"> &
    ExtendedQueryOptions
): UseQueryResult<T, ApiError> => {
  const { toastOnError } = { ...defaultOptions, ...(options || {}) };
  const query = useQuery<T, ApiError>(key, func, options);
  if (query.isError) {
    if (query.error.status == 401) {
      console.log("Reloading window to force a new login");
      window.location.reload();
    } else if (toastOnError) {
    }
  }

  return query;
};

export default useQueryWrapper;
