import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MprService, RegistrationCreateDto } from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { SelectControl } from "../common";
import { useQueryWrapper } from "../../hooks";

const Ean13SelectControl = () => {
  const { data, isInitialLoading } = useQueryWrapper(
    ["getApiMprOrganisationInformation"],
    () => MprService.getApiMprOrganisationInformation(),
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const { setValue, watch } = useFormContext<RegistrationCreateDto>();
  const marketRole = watch("marketRole")!;

  const options = useMemo(
    () =>
      data?.marketParties
        ?.filter((m) => m.marketRole === marketRole)
        .map((m) => ({
          value: m.ean!,
          label: `${m.ean} - ${m.name!}`,
        })) ?? [],
    [data, marketRole]
  );

  const map = safePropertyMap<RegistrationCreateDto>();

  useEffect(() => {
    if (options.length) {
      setValue("organisatieEan13Code", options[0].value);
    }
  }, [options, setValue]);

  return (
    <SelectControl
      options={options}
      name={map("organisatieEan13Code")}
      required
      isLoading={isInitialLoading}
    />
  );
};

export default Ean13SelectControl;
