import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type OrganizationUnitCardRowProps = {
  label: string;
  value?: React.ReactNode;
};
const OrganizationUnitCardRow = ({
  label,
  value,
}: OrganizationUnitCardRowProps) => {
  if (!value) {
    return null;
  }
  return (
    <Grid container item spacing={2}>
      <Grid item xs={5}>
        <Typography variant="body2">
          <FormattedMessage id={label} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
          sx={{ wordBreak: "break-word" }}
          color="common.black"
          variant="body2"
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrganizationUnitCardRow;
