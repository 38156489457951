import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { DateControl, InputControl } from "../common";

const RecognitionDetails = () => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const { watch } = useFormContext<RegistrationCreateDto>();
  const marketRole = watch("marketRole")!;
  const showElec = watch("electricity");
  const showGas = watch("gas");

  const showRecogMeas =
    marketRole === edsn_Marktrol.MVMEETVERANTWOORDELIJKE &&
    (showElec || showGas);

  if (showRecogMeas) {
    return (
      <>
        <Typography variant="h6" color="secondary">
          <FormattedMessage id="recognition" />
        </Typography>
        <InputControl name={map("licenseHolder")} label="licenseHolderMv" />
        {showElec && (
          <DateControl
            name={map("recognitionElecStartDate")}
            label="startdateRecogElec"
          />
        )}
        {showGas && (
          <DateControl
            name={map("recognitionGasStartDate")}
            label="startdateRecogGas"
          />
        )}
      </>
    );
  }
  return null;
};

export default RecognitionDetails;
