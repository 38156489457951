import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { DateControl, InputControl } from "../common";
import ProductCspSelectControl from "./ProductCspSelectControl";

const CSPDetails = () => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const { watch } = useFormContext<RegistrationCreateDto>();
  const marketRole = watch("marketRole")!;

  const CSP = marketRole === edsn_Marktrol.CSPCONGESTIE_SERVICE_PROVIDER;

  if (CSP) {
    return (
      <>
        <Typography variant="h6" color="secondary">
          <FormattedMessage id="products" />
        </Typography>
        <ProductCspSelectControl
          required
          name={map("cspProducts")}
          label="product"
        />

        <Typography variant="h6" color="secondary">
          <FormattedMessage id="recognition" />
        </Typography>
        <InputControl name={map("nameCspInRegister")} label="nameCSP" />
        <DateControl
          name={map("recognitionStartDateCsp")}
          label="startdateRecog"
        />
      </>
    );
  }
  return null;
};

export default CSPDetails;
