import { useMemo } from "react";
import { FormStepper } from "../../common";
import {
  SigneeStep,
  MarketRoleStep,
  RegistrationChangeStep,
  ContactDetailChangeStep,
} from "../steps";
import { useFormContext } from "react-hook-form";
import {
  edsn_Marktrol,
  RegistrationChangeDto,
} from "../../../openapi/edsn-webapi";
import { FormattedMessage, useIntl } from "react-intl";
import { StepType } from "../../common/Form/FormStepper";
import { Link } from "@mui/material";
import { Language, useLocalizationStore } from "../../Localization";

const RegistrationChangeFormStepper = () => {
  const { watch } = useFormContext<RegistrationChangeDto>();
  const intl = useIntl();
  const marketRole = watch("marketRole");
  const isLvRnbGds = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM,
    edsn_Marktrol.RNBREGIONALE_NETBEHEERDER,
  ].includes(marketRole);

  const language = useLocalizationStore((state) => state.language);

  const steps = useMemo(
    () =>
      [
        { label: "marketRole", Component: () => <MarketRoleStep /> },
        {
          label: "companyDetails",
          Component: () => <RegistrationChangeStep />,
        },
        {
          label: "contactDetails",
          Component: () => <ContactDetailChangeStep />,
          information: isLvRnbGds
            ? intl.formatMessage(
                { id: "contactDetailsInformationChangeLvRnbGds" },
                {
                  link: (
                    <Link
                      variant="subtitle2"
                      color={"#FFFFFF"}
                      underline="always"
                      href={`${process.env.REACT_APP_MIJN_PIE_BASE_URL}/contactpersonenregister`}
                      target="_blank"
                    >
                      <FormattedMessage id="contactDetailsInformationChangeLvRnbGdsLinkText" />
                    </Link>
                  ),
                  linkInfo: (
                    <Link
                      variant="subtitle2"
                      color={"#FFFFFF"}
                      underline="always"
                      href={`${process.env.REACT_APP_PIE_BASE_URL}/${
                        language === Language.DUTCH
                          ? "diensten/contactpersoon-registratie"
                          : "en-GB/services/contact-person-registration-and-management"
                      }`}
                      target="_blank"
                    >
                      <FormattedMessage id="contactDetailsInformationChangeLvRnbGdsLinkInfoText" />
                    </Link>
                  ),
                }
              )
            : undefined,
        },
        { label: "signeeDetails", Component: () => <SigneeStep /> },
      ] as Array<StepType>,
    [isLvRnbGds, intl, language]
  );

  return <FormStepper steps={steps} />;
};

export default RegistrationChangeFormStepper;
