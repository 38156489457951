import { Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { CongestionInterestDto } from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { InputControl } from "../common";
import ContactPersonDetails from "./ContactPersonDetails";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const General = () => {
  const map = safePropertyMap<CongestionInterestDto>();
  const intl = useIntl();

  useEffect(() => {
    if (process.env.REACT_APP_UNIVERSAL_ANALYTICS_TRACKING_ID) {
      ReactGA.send({
        hitType: "pageview",
        page: `${window.location.pathname}${window.location.search}/step3`,
      });
    }
  }, []);
  return (
    <>
      <Typography variant="h5" color="secondary">
        <FormattedMessage id="general" />
      </Typography>
      <InputControl required label="accountName" name={map("companyName")} />
      <InputControl label="kvkNummer" name={map("cocNumber")} />
      <InputControl required name={map("name")} />
      <InputControl required name={map("function")} />
      <InputControl required label="phoneNumber" name={map("telephone")} />
      <InputControl
        required
        pattern={{
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: intl.formatMessage({ id: "invalidEmailAddress" }),
        }}
        type="email"
        label="email"
        name={map("emailAddress")}
      />
      <ContactPersonDetails />
    </>
  );
};

export default General;
