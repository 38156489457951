import { Box, Alert, Link, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  edsn_Marktrol,
  RegistrationChangeDto,
} from "../../../openapi/edsn-webapi";
import { safePropertyMap } from "../../../utils";
import { YesNoControl } from "../../common";
import ContactDetailStep from "./ContactDetailStep";
import { useEffect } from "react";

const ContactDetailsChangeStep = () => {
  const map = safePropertyMap<RegistrationChangeDto>();
  const { watch, getValues, setValue } =
    useFormContext<RegistrationChangeDto>();
  const showContactDetails =
    (watch("contactDetailsChanged") as any) === "*true*";

  const marketRole = watch("marketRole");
  const isLvRnbGds = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM,
    edsn_Marktrol.RNBREGIONALE_NETBEHEERDER,
  ].includes(marketRole);

  const validateYesNoControls = (value: string) =>
    value === "*true*" ||
    getValues([
      "organizationUnitsChanged",
      "preferredPartyElectricityContractChanged",
      "preferredPartyGasContractChanged",
      "companyDetailsChanged",
      "addressUpdated",
    ]).some((m) => (m as any) === "*true*");

  useEffect(() => {
    if (!showContactDetails) {
      setValue("contactDefault", undefined);
    }
  }, [showContactDetails]);

  return (
    <Box>
      {isLvRnbGds ? (
        <>
          <YesNoControl
            name={map("contactDetailsChanged")}
            validate={validateYesNoControls}
            defaultValue={false}
          />
          {showContactDetails && (
            <>
              <ContactDetailStep />
            </>
          )}
        </>
      ) : (
        <Typography variant="subtitle2">
          <FormattedMessage
            id="contactDetailsInformationChangeLvRnbGds"
            values={{
              link: (
                <Link
                  variant="subtitle2"
                  underline="hover"
                  href={`${process.env.REACT_APP_MIJN_PIE_BASE_URL}/contactpersonenregister`}
                  target="_blank"
                >
                  <FormattedMessage id="contactDetailsInformationChangeLvRnbGdsLinkText" />
                </Link>
              ),
              linkInfo: (
                <Link
                  variant="subtitle2"
                  underline="hover"
                  href={`${process.env.REACT_APP_PIE_BASE_URL}/diensten/contactpersoon-registratie`}
                  target="_blank"
                >
                  <FormattedMessage id="contactDetailsInformationChangeLvRnbGdsLinkInfoText" />
                </Link>
              ),
            }}
          />
        </Typography>
      )}
    </Box>
  );
};

export default ContactDetailsChangeStep;
