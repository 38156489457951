import { useEffect } from "react";
import useToaster from "./useToaster";
import "./Toast.scss";
import { Alert } from "@mui/material";

type ToastProps = {
  type: "info" | "success" | "error" | "warning";
  message: string;
  id: string;
};

const Toast = ({ type, message, id }: ToastProps) => {
  const remove = useToaster((state) => state.removeToast);
  useEffect(() => {
    const timeout = setTimeout(() => {
      remove(id);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <Alert className="edsn-toast" severity="error" sx={{ mb: 1 }}>
      {message}
    </Alert>
  );
};

export default Toast;
