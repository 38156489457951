import { Button, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  edsn_Marktrol,
  OrganizationUnitDto,
  RegistrationCreateDto,
} from "../../../openapi/edsn-webapi";
import { Modal } from "../../common";
import OrganizaztionUnitForm from "./OrganizaztionUnitForm";

type OrganizationUnitModalProps = {
  open: boolean;
  onClose?: () => void;
  onSubmit: (dto: OrganizationUnitDto) => void;
  submitLabel?: string;
  data?: OrganizationUnitDto;
  eanDisabled: boolean;
};

const OrganizationUnitModal = ({
  open,
  onClose,
  onSubmit,
  data,
  eanDisabled,
}: OrganizationUnitModalProps) => {
  const { watch } = useFormContext<RegistrationCreateDto>();
  const marketRole = watch("marketRole")!;
  const showLV = [edsn_Marktrol.LVLEVERANCIER].includes(marketRole);

  return (
    <Modal open={open} onClose={onClose}>
      <Typography variant="h6">
        <FormattedMessage id="adminParty" />
      </Typography>
      <OrganizaztionUnitForm
        eanDisabled={eanDisabled}
        onSubmit={onSubmit}
        defaultValues={data}
        showFinancialProps={showLV}
        marketRole={marketRole}
        renderAfter={
          <>
            <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="contained">
              <FormattedMessage id={data ? "save" : "add"} />
            </Button>
            <Button sx={{ mt: 1, mr: 1 }} variant="text" onClick={onClose}>
              <FormattedMessage id="close" />
            </Button>
          </>
        }
      />
    </Modal>
  );
};

export default OrganizationUnitModal;
