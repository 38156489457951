import Spinner from "./loader.svg";
import "./OverlayLoader.scss";

const OverlayLoader = () => {
  return (
    <div className="overlay-loader">
      <img src={Spinner} alt="Loading..." />
    </div>
  );
};

export default OverlayLoader;
