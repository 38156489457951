import { Alert, FormLabel } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { InputControl } from "../common";

type KWControlProps = {
  name: string;
  label?: string;
};

const KWControl = ({ name, label }: KWControlProps) => {
  return (
    <>
      <FormLabel required>
        <FormattedMessage id={label ?? name} />
      </FormLabel>
      <InputControl
        min={0}
        required
        noLabel
        endAdornment={<>kW</>}
        tooltip="zeroWhenNotApplicable"
        width={150}
        type="number"
        name={name}
      />
    </>
  );
};

export default KWControl;
