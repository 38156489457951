import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { Box } from "@mui/system";
import { InputHTMLAttributes } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useError } from "../../../hooks";
import Tooltip from "../Tooltip";
import defaultValidationRules from "./defaultValidationRules";

type InputControlProps = {
  label?: string;
  name: string;
  onChange?: (checked: boolean) => void;
  showFormHelperTextOnError?: boolean;
  tooltip?: string;
  tooltipTranslated?: boolean;
};

const InputControl = ({
  disabled,
  onChange,
  showFormHelperTextOnError = true,
  tooltip,
  tooltipTranslated,
  ...props
}: InputControlProps &
  RegisterOptions &
  Omit<InputHTMLAttributes<HTMLInputElement>, "pattern" | "onChange">) => {
  const { label, name } = props;
  const { control } = useFormContext();
  const intl = useIntl();
  const rules = defaultValidationRules(props);
  const error = useError(name);

  const handleOnChange =
    (fieldOnChange: (e: any) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      fieldOnChange(event);
      if (onChange) {
        onChange(checked);
      }
    };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <FormControl error={!!error}>
          <Box display="flex">
            <FormControlLabel
              sx={{ mr: 0 }}
              label={intl.formatMessage({ id: label ?? name })}
              control={
                <Checkbox
                  required={props.required}
                  disabled={disabled}
                  {...field}
                  onChange={handleOnChange(field.onChange)}
                  checked={!!field.value}
                />
              }
            />
            {tooltip && (
              <Tooltip title={tooltip} titleTranslated={tooltipTranslated} />
            )}
          </Box>

          {showFormHelperTextOnError && error?.message && (
            <FormHelperText>
              <FormattedMessage id={error.message as any} />
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default InputControl;
