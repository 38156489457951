import create from "zustand";

export interface RegistrationState {
  isUpdate: boolean;
  permissionDocumentGas?: File;
  permissionDocumentElectricity?: File;
  setIsUpdate: (val: boolean) => void;
  setPermissionDocumentGas: (val: File | undefined) => void;
  setPermissionDocumentelectricity: (val: File | undefined) => void;
}

const useRegistration = create<RegistrationState>((set) => ({
  isUpdate: false,
  setIsUpdate: (isUpdate) => set(() => ({ isUpdate })),
  setPermissionDocumentGas: (permissionDocumentGas) =>
    set(() => ({ permissionDocumentGas })),
  setPermissionDocumentelectricity: (permissionDocumentElectricity) =>
    set(() => ({ permissionDocumentElectricity })),
}));

export default useRegistration;
