import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuthStore from "./useAuthStore";

const AuthCallback: FC = () => {
  const navigate = useNavigate();
  const setAuthState = useAuthStore((state) => state.setAuthState);
  let [searchParams, _] = useSearchParams();

  const redirect_uri = searchParams.get("redirect_uri");
  const state = searchParams.get("state");
  const exp = searchParams.get("exp");
  const access_token = searchParams.get("access_token");

  useEffect(() => {
    if (exp && access_token) {
      setAuthState({ access_token, exp: new Date(exp) });
      navigate(redirect_uri ?? "/", { replace: true });
    }
  }, [exp, access_token, redirect_uri]);

  return <div>Het is niet gelukt om in te loggen</div>;
};

export default AuthCallback;
