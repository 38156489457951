import { PageBody, PageHeader } from "../../common";
import RegistrationCancelForm from "./RegistrationCancelForm";

const RegistrationCancelFormPage = () => {
  return (
    <>
      <PageHeader maxWidth="md" title="cancelRegistrationTitle" />
      <PageBody maxWidth="md">
        <RegistrationCancelForm />
      </PageBody>
    </>
  );
};

export default RegistrationCancelFormPage;
