import { InputControl, SelectControl } from "../common";
import { useFormContext } from "react-hook-form";
import {
  AdjustablePower,
  CongestionInterestDto,
} from "../../openapi/edsn-webapi";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Button, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { safePropertyMap } from "../../utils";
import { ean18Validator } from "../../validators";
import KWControl from "./KWControl";
import CheckboxControl from "../common/Form/CheckboxControl";
import { useEffect } from "react";

type EanInputProps = {
  index: number;
};
const EanInput = ({ index }: EanInputProps) => {
  const { setValue, getValues, watch } =
    useFormContext<CongestionInterestDto>();
  const intl = useIntl();
  const adjustablePowerOptions = Object.values(AdjustablePower).map((m) => ({
    value: m,
    label: intl.formatMessage({ id: `adjustablePower.${m}` }),
  }));

  const noEanYet = watch(`connections.${index}.noEanYet`);
  const map = safePropertyMap<CongestionInterestDto>();
  const adjustablePower = watch(`connections.${index}.adjustablePower`);
  const isOther = adjustablePower?.includes(AdjustablePower.OTHER);
  const showDeliveryMorekW =
    adjustablePower &&
    [
      AdjustablePower.STORAGE,
      AdjustablePower.WKK,
      AdjustablePower.OTHER,
    ].includes(adjustablePower);

  const showDeliveryLesskW =
    adjustablePower &&
    [
      AdjustablePower.STORAGE,
      AdjustablePower.SOLAR_PARK,
      AdjustablePower.WINDMILLS,
      AdjustablePower.WKK,
      AdjustablePower.OTHER,
    ].includes(adjustablePower);

  const showTakeMorekW =
    adjustablePower &&
    [
      AdjustablePower.STORAGE,
      AdjustablePower.FLEXIBLE_PRODUCTION_PROCESS,
      AdjustablePower.EBOILER,
      AdjustablePower.OTHER,
    ].includes(adjustablePower);

  const showTakeLesskW =
    adjustablePower &&
    [
      AdjustablePower.STORAGE,
      AdjustablePower.FLEXIBLE_PRODUCTION_PROCESS,
      AdjustablePower.EBOILER,
      AdjustablePower.OTHER,
    ].includes(adjustablePower);

  const deleteConnection = () => {
    setValue(
      "connections",
      getValues("connections")!.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (noEanYet) {
      setValue(`connections.${index}.ean`, "");
    }
  }, [noEanYet]);

  return (
    <>
      <Typography variant="h5" color="secondary">
        <FormattedMessage id="connectionTitle" values={{ index: index + 1 }} />
      </Typography>
      {!noEanYet && (
        <InputControl
          label="eanCongestionInterest"
          tooltip="congestionEanTooltip"
          name={map(`connections.${index}.ean`)}
          required
          validate={ean18Validator}
        />
      )}
      <CheckboxControl
        label="noEanYet"
        name={map(`connections.${index}.noEanYet`)}
      />
      {noEanYet && (
        <>
          <InputControl
            label="identificationFutureConnection"
            placeholder={intl.formatMessage({
              id: "identificationFutureConnectionTooltip",
            })}
            name={map(`connections.${index}.identificationFutureConnection`)}
            required
          />
          <Alert severity="warning">
            <FormattedMessage id={"noEanYetWraningMessage"} />
          </Alert>
        </>
      )}

      <SelectControl
        required
        label="adjustablePower"
        name={map(`connections.${index}.adjustablePower`)}
        options={adjustablePowerOptions}
      />
      {isOther && (
        <InputControl
          required
          label="explenation"
          name={map(`connections.${index}.adjustablePowerOther`)}
        />
      )}
      {!!adjustablePower && (
        <Typography variant="h6">
          <FormattedMessage id="indicationAdjustablePower" />
        </Typography>
      )}
      {showDeliveryMorekW && (
        <KWControl
          label="deliveryMorekW"
          name={map(`connections.${index}.deliveryMorekW`)}
        />
      )}
      {showDeliveryLesskW && (
        <KWControl
          label="deliveryLesskW"
          name={map(`connections.${index}.deliveryLesskW`)}
        />
      )}
      {showTakeMorekW && (
        <KWControl
          label="takeMorekW"
          name={map(`connections.${index}.takeMorekW`)}
        />
      )}
      {showTakeLesskW && (
        <KWControl
          label="takeLesskW"
          name={map(`connections.${index}.takeLesskW`)}
        />
      )}
      {index > 0 && (
        <Button
          sx={{ mb: 2 }}
          onClick={deleteConnection}
          variant="outlined"
          startIcon={<DeleteIcon />}
        >
          <FormattedMessage
            id="removeConnection"
            values={{ index: index + 1 }}
          />
        </Button>
      )}
    </>
  );
};

export default EanInput;
