/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum edsn_BSPproduct {
    FCR = 'FCR',
    A_FRR = 'aFRR',
    MARIPRODUCT = 'MARIproduct',
    NOODVERMOGEN = 'Noodvermogen',
}
