import {
  AdjustablePower,
  CongestionInterestDto,
} from "../../openapi/edsn-webapi";
import EanInput from "./EanInput";
import { Button, Divider } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext } from "react-hook-form";

type ean = {
  ean: string;
  power?: AdjustablePower;
};
const EanInputs = () => {
  const { setValue, watch } = useFormContext<CongestionInterestDto>();

  const connections = watch("connections") ?? [];

  const addConnection = () => {
    setValue(`connections.${connections.length}`, {});
  };
  return (
    <>
      {connections.map((_, i) => (
        <EanInput key={i} index={i} />
      ))}
      <Divider sx={{ my: 2 }} />
      <Button
        hidden={connections.length >= 5}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={addConnection}
      >
        <FormattedMessage id="addAnotherConnection" />
      </Button>
    </>
  );
};

export default EanInputs;
