import create from "zustand";
import { v4 as uuidv4 } from "uuid";

export interface CreateToast {
  type: "info" | "success" | "error" | "warning";
  message: string;
}

interface Toast extends CreateToast {
  id: string;
  hiding?: boolean;
}

type ToasterStoreState = {
  toasts: Toast[];
  addToast: (toast: CreateToast) => void;
  removeToast: (id: string) => void;
};

const useToaster = create<ToasterStoreState>((set) => ({
  toasts: [],
  addToast: (toast) =>
    set((state) => ({ toasts: [...state.toasts, { ...toast, id: uuidv4() }] })),
  removeToast: (id) =>
    set((state) => ({ toasts: state.toasts.filter((m) => m.id !== id) })),
}));

export default useToaster;
