import { createTheme } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#13af96",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#075aaa",
      contrastText: "#FFF",
    },
  },
  typography: {
    body2: {
      color: "#79879F",
    },
    h6: {
      marginTop: "1rem",
    },
    button: {
      textTransform: "none",
    },
  },
});
theme = createTheme(theme, {
  components: {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          "& .MuiTooltip-tooltip": {
            ...theme.typography.subtitle2,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&[readonly]": {
            fontStyle: "italic",
            color: "rgba(0, 0, 0, 0.38)",
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "#075aaa",
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          ".MuiStepLabel-label": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.divider}`,
          marginBottom: theme.spacing(1),
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, .03)",
          "&.Mui-expanded": {
            borderBottom: "1px solid rgba(0, 0, 0, .125)",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiButton-root": {
            textTransform: "none",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          ".MuiIconButton-root": {
            marginRight: "-0.5rem",
          },
        },
      },
    },
  },
});
export default theme;
