import useToaster from "./useToaster";
import Toast from "./Toast";
import "./Toaster.scss";

const Toaster = () => {
  const toasts = useToaster((state) => state.toasts);
  return (
    <div className="edsn-toaster pe-3">
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default Toaster;
