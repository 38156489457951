import { Alert, Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { phoneValidator } from "../../validators";
import { InputControl } from "../common";
import ContactWithPhonePropType from "./ContactWithPhonePropType";
import { useFormContext } from "react-hook-form";

type ContactControlProps = {
  fieldname: ContactWithPhonePropType;
};

const ContactControl = ({ fieldname }: ContactControlProps) => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const intl = useIntl();
  const { watch } = useFormContext<RegistrationCreateDto>();
  const marketRole = watch("marketRole");
  const isLvRnbGds = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM,
    edsn_Marktrol.RNBREGIONALE_NETBEHEERDER,
  ].includes(marketRole);

  const infoLabel = isLvRnbGds
    ? "contactDetailsInformationLvRnbGds"
    : "contactDetailsInformationOther";

  const showInfo = fieldname == "contactDefault";

  return (
    <Box sx={{ position: "relative" }}>
      {showInfo && (
        <Alert severity="info">
          <FormattedMessage id={infoLabel} />
        </Alert>
      )}
      <InputControl
        required
        label="initials"
        name={map(`${fieldname}.initials`)}
      />
      <InputControl label="insertion" name={map(`${fieldname}.middleName`)} />
      <InputControl
        required
        label="lastname"
        name={map(`${fieldname}.lastName`)}
      />
      <InputControl
        required
        pattern={{
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: intl.formatMessage({ id: "invalidEmailAddress" }),
        }}
        type="email"
        label="email"
        name={map(`${fieldname}.emailAddress`)}
      />
      <InputControl
        required
        validate={phoneValidator}
        placeholder="+31612345678"
        label="phoneNumber"
        name={map(`${fieldname}.telephone`)}
      />
    </Box>
  );
};

export default ContactControl;
