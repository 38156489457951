import * as React from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { ClickAwayListener, IconButton } from "@mui/material";
import { useIntl } from "react-intl";
import InfoIcon from "@mui/icons-material/Info";

type KbsTooltipProps = {
  title: string;
  titleTranslated?: boolean;
} & Omit<TooltipProps, "onClose" | "open" | "title" | "children">;

const KbsTooltip = ({ title, titleTranslated, ...props }: KbsTooltipProps) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={titleTranslated ? title : intl.formatMessage({ id: title })}
        {...props}
      >
        <IconButton onClick={handleTooltipOpen}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default KbsTooltip;
