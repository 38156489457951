import { RegisterOptions, useFormContext } from "react-hook-form";
import RadioControl from "./RadioControl";
import { useEffect } from "react";

type YesNoProps = {
  label?: string;
  name: string;
  defaultValue?: boolean;
  yesLabel?: string;
  noLabel?: string;
  row?: boolean;
};

const YesNoControl = ({
  yesLabel = "yes",
  noLabel = "no",
  row = true,
  onChange,
  ...props
}: YesNoProps & RegisterOptions) => {
  const { setValue, getValues } = useFormContext();
  const defaultValue =
    props.defaultValue === true
      ? "*true*"
      : props.defaultValue === false
      ? "*false*"
      : undefined;

  useEffect(() => {
    const value = getValues(props.name);
    if (defaultValue !== undefined && value === undefined) {
      setValue(props.name, defaultValue);
    } else if (value === true) {
      setValue(props.name, "*true*");
    } else if (value === false) {
      setValue(props.name, "*false*");
    }
  }, []);

  return (
    <RadioControl
      {...props}
      defaultValue={defaultValue}
      row={row}
      options={[
        {
          // using custom values for true and false because otherwise the material radio button will always check the "false" input control
          // this is because their value check sees false | "false" | undefined | null as the same...
          value: "*true*",
          label: yesLabel,
        },
        {
          value: "*false*",
          label: noLabel,
        },
      ]}
    />
  );
};

export default YesNoControl;
