import {
  Box,
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import Tooltip from "../Tooltip";

export interface StepType {
  label: string;
  Component: () => JSX.Element;
  information?: string;
}
type FormStepperProps = {
  steps: Array<StepType>;
};

const FormStepper = ({ steps }: FormStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const { trigger } = useFormContext();

  const handleNext = async () => {
    const valid = await trigger();
    if (valid) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map(({ Component, label, information }, index) => (
        <Step key={label}>
          <StepLabel>
            <Box display={"flex"} justifyItems={"center"} gap={"4px"}>
              <FormattedMessage id={label} />
              {information && (
                <Tooltip sx={{ p: 0 }} title={information} titleTranslated />
              )}
            </Box>
          </StepLabel>
          <StepContent>
            <Component />
            <Box sx={{ mb: 2 }}>
              <Button
                hidden={index === 0}
                onClick={handlePrevious}
                sx={{ mt: 1, mr: 1 }}
              >
                <FormattedMessage id="previous" />
              </Button>
              <Button
                hidden={index === steps.length - 1}
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                <FormattedMessage id="next" />
              </Button>
              <Button
                hidden={index !== steps.length - 1}
                variant="contained"
                type="submit"
                sx={{ mt: 1, mr: 1 }}
              >
                <FormattedMessage id="submit" />
              </Button>
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default FormStepper;
