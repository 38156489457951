import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { RegistrationCreateDto } from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { ean13Validator } from "../../validators";
import { InputControl } from "../common";

type Ean13ControlProps = {
  autoChangeFirstOrganizationUnit?: boolean;
};

const Ean13Control = ({
  autoChangeFirstOrganizationUnit,
}: Ean13ControlProps) => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const { setValue, getValues, watch } =
    useFormContext<RegistrationCreateDto>();
  const intl = useIntl();
  const marketRole = watch("marketRole");

  const genericTooltip = intl.formatMessage(
    { id: "tooltipEan" },
    {
      marketRole: intl.formatMessage({ id: `edsn_Marktrol.${marketRole}` }),
    }
  );

  const onChange =
    (onChange: (...event: any[]) => void) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const organizationUnits = getValues("organizationUnits");
      if (organizationUnits && organizationUnits?.length >= 1) {
        setValue(`organizationUnits.${0}`, {
          ...organizationUnits[0],
          ean13: e.target.value,
        });
      }
      onChange(e);
    };
  return (
    <InputControl
      required
      onChange={autoChangeFirstOrganizationUnit ? onChange : undefined}
      validate={ean13Validator}
      name={map("organisatieEan13Code")}
      tooltip={marketRole ? genericTooltip : "selectMarketRoleFirst"}
      tooltipTranslated={!!marketRole}
    />
  );
};

export default Ean13Control;
