import { Backdrop, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const widthMap = {
  small: 400,
  medium: 600,
  large: 900,
};

type EdsnModalProps = {
  children: React.ReactNode;
  open: boolean;
  keepMounted?: boolean;
  onClose?: () => void;
  size?: "small" | "medium" | "large";
};

const EdsnModal = ({
  children,
  open,
  keepMounted,
  onClose,
  size = "small",
}: EdsnModalProps) => {
  const width = widthMap[size];
  return (
    <Modal
      keepMounted={keepMounted}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, width }}>{children}</Box>
      </Fade>
    </Modal>
  );
};

export default EdsnModal;
