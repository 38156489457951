import { ReCAPTCHAControl } from "../common";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { safePropertyMap } from "../../utils";
import { CongestionInterestDto } from "../../openapi/edsn-webapi";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const FinalStep = () => {
  const map = safePropertyMap<CongestionInterestDto>();
  useEffect(() => {
    if (process.env.REACT_APP_UNIVERSAL_ANALYTICS_TRACKING_ID) {
      ReactGA.send({
        hitType: "pageview",
        page: `${window.location.pathname}${window.location.search}/step4`,
      });
    }
  }, []);
  return (
    <>
      <ReCAPTCHAControl name={map("captcha")} />
      <Typography sx={{ mt: 1 }} variant="body1">
        <FormattedMessage id="congestionInformationAcknowledgement" />
      </Typography>
    </>
  );
};

export default FinalStep;
