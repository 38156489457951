import { FormControl, FormHelperText } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocalizationStore } from "../../Localization";
import { useError } from "../../../hooks";

type ReCAPTCHAControlProps = {
  name: string;
};

const ReCAPTCHAControl = ({ name }: ReCAPTCHAControlProps) => {
  const { control } = useFormContext();
  const error = useError(name);
  const language = useLocalizationStore((state) => state.language);
  return (
    <Controller
      name={name}
      rules={{ required: { value: true, message: "requiredMessage" } }}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth error={!!error}>
          <ReCAPTCHA
            hl={language}
            style={{ color: "red" }}
            sitekey="6Lcnh0EeAAAAACdBFFYhaq8dnzGTTPBdX08RdkH0"
            onChange={field.onChange}
          />
          {error?.message && (
            <FormHelperText sx={{ ml: 0 }}>
              <FormattedMessage id={error.message as any} />
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default ReCAPTCHAControl;
