import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { RegistrationCreateDto } from "../../../openapi/edsn-webapi";
import { safePropertyMap } from "../../../utils";
import { InputControl } from "../../common";

const SigneeStep = () => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const intl = useIntl();

  return (
    <Box sx={{ position: "relative" }}>
      <InputControl
        required
        label="initials"
        name={map("signee.initials")}
        tooltip="tooltipInitials"
      />
      <InputControl label="insertion" name={map("signee.middleName")} />
      <InputControl
        required
        label="lastname"
        name={map("signee.lastName")}
        tooltip="tooltipLastname"
      />
      <InputControl
        required
        pattern={{
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: intl.formatMessage({ id: "invalidEmailAddress" }),
        }}
        label="email"
        name={map("signee.emailAddress")}
        tooltip="tooltipEmail"
      />
    </Box>
  );
};

export default SigneeStep;
