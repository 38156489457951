import { Breakpoint, Container } from "@mui/material";
import "./PageBody.scss";

type PageBodyProps = {
  children: React.ReactNode;
  maxWidth?: Breakpoint | false;
};

const PageBody = ({ children, maxWidth }: PageBodyProps) => (
  <Container maxWidth={false} className="page-body">
    <Container
      maxWidth={maxWidth}
      sx={{
        boxShadow: 2,
        borderRadius: 1.5,
        p: 3,
        backgroundColor: "#fff",
      }}
    >
      {children}
    </Container>
  </Container>
);

export default PageBody;
