import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ChangeEvent, Fragment, useRef } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { useError } from "../../../hooks";
import defaultValidationRules from "./defaultValidationRules";

type RadioControlProps = {
  label?: string;
  name: string;
  row?: boolean;
  defaultValue?: any;
  onChange?: (
    onChange: (...event: any[]) => void
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  options: {
    value: any;
    label: string;
    selectedRenderBelow?: React.ReactNode;
  }[];
};

const RadioControl = ({
  label,
  name,
  row,
  defaultValue = false,
  options,
  onChange,
  ...props
}: RadioControlProps & RegisterOptions) => {
  const { control } = useFormContext();
  const error = useError(name);
  const id = useRef(uuidv4());
  const intl = useIntl();
  const rules = defaultValidationRules(props);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl
          fullWidth
          error={!!error}
          sx={{ mt: 2 }}
          required={!!props.required}
        >
          <FormLabel id={id.current}>
            <FormattedMessage id={label ?? name} />
          </FormLabel>
          <RadioGroup
            row={row}
            aria-labelledby={id.current}
            defaultValue={defaultValue}
            {...field}
            onChange={onChange ? onChange(field.onChange) : field.onChange}
            value={field.value ?? defaultValue}
          >
            {options.map(({ value, label, selectedRenderBelow }) => (
              <Fragment key={value}>
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  label={intl.formatMessage({ id: label ?? name })}
                />
                {field.value === value && selectedRenderBelow}
              </Fragment>
            ))}
          </RadioGroup>
          {error?.message && (
            <FormHelperText sx={{ ml: 0 }}>
              <FormattedMessage id={error.message as any} />
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default RadioControl;
