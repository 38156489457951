import { useRef } from "react";
import { FormStepper } from "../../common";
import {
  SigneeStep,
  RegistrationStep,
  MarketRoleStep,
  ContactDetailStep,
} from "../steps";

const RegistrationFormStepper = () => {
  const steps = useRef([
    { label: "marketRole", Component: () => <MarketRoleStep /> },
    { label: "companyDetails", Component: () => <RegistrationStep /> },
    {
      label: "contactDetails",
      Component: () => <ContactDetailStep isCreate />,
    },
    { label: "signeeDetails", Component: () => <SigneeStep /> },
  ]);

  return <FormStepper steps={steps.current} />;
};

export default RegistrationFormStepper;
