import {
  Breakpoint,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import "./PageHeader.scss";
import LockIconOutlined from "@mui/icons-material/LockOutlined";
import { useAuthStore } from "../../Auth";
import { LanguageSelect } from "../../Localization";
import Link from "@mui/material/Link";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type PageHeaderProps = {
  title: string;
  maxWidth?: Breakpoint | false;
  navigateBackUrl?: string;
  navigateBackLabel?: string;
};

const PageHeader = ({
  title,
  maxWidth,
  navigateBackUrl,
  navigateBackLabel,
}: PageHeaderProps) => {
  const signout = useAuthStore((state) => state.signout);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return (
    <Container
      className="page-header"
      maxWidth={false}
      sx={{ bgcolor: "secondary.main", position: "relative" }}
    >
      <Container
        sx={{ position: "absolute", top: 0, left: 0, m: 2, zIndex: 1000 }}
      >
        <Link href={process.env.REACT_APP_PIE_BASE_URL}>
          <img
            src={`${process.env.PUBLIC_URL}/PiELogo.svg`}
            alt="Partners in Energie"
          />
        </Link>
      </Container>
      <Container maxWidth={maxWidth}>
        <Typography variant="h1">
          {navigateBackUrl && (
            <Link
              href={navigateBackUrl}
              variant="body2"
              color="secondary.contrastText"
              underline="hover"
            >
              <Grid container alignItems="center">
                <Grid item>
                  <ArrowBackIcon sx={{ display: "block" }} />
                </Grid>
                <Grid item>
                  <FormattedMessage id={navigateBackLabel} />
                </Grid>
              </Grid>
            </Link>
          )}
          <FormattedMessage id={title} />
        </Typography>
        <ButtonGroup
          sx={{ position: "absolute", top: 0, right: 0, m: 2, zIndex: 1000 }}
        >
          <LanguageSelect variant="standard" disableUnderline />
          {isAuthenticated() && (
            <Button
              className="signout"
              variant="contained"
              size="large"
              onClick={signout}
            >
              <FormattedMessage id="signout"></FormattedMessage>
              <LockIconOutlined sx={{ marginLeft: 0.5 }} />
            </Button>
          )}
        </ButtonGroup>
      </Container>
    </Container>
  );
};

export default PageHeader;
