/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactPersonForCongestion {
    SAME_AS_ABOVE = 'SameAsAbove',
    SOMEONE_IN_THE_COMPANY = 'SomeoneInTheCompany',
    SUPPLIER = 'Supplier',
    CONGESTION_SERVICE_PROVIDER = 'CongestionServiceProvider',
    OTHER = 'Other',
}
