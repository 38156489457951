import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import EanInputs from "./EanInputs";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  CongestionInterestDto,
  GridOperatorService,
} from "../../openapi/edsn-webapi";
import { SelectControl } from "../common";
import { getSelectOptions, safePropertyMap } from "../../utils";
import { useQueryWrapper } from "../../hooks";

const ConnectionDetailStep = () => {
  const map = safePropertyMap<CongestionInterestDto>();

  const { data, isLoading } = useQueryWrapper(
    ["gridOperators"],
    GridOperatorService.getApiGridOperatorSelectOptions
  );
  const gridOperators = getSelectOptions(data?.items);

  const { watch } = useFormContext<CongestionInterestDto>();
  const moreThanFiveConnections =
    (watch("moreThanFiveConnections") as any) === "*true*";
  useEffect(() => {
    if (process.env.REACT_APP_UNIVERSAL_ANALYTICS_TRACKING_ID) {
      ReactGA.send({
        hitType: "pageview",
        page: `${window.location.pathname}${window.location.search}/step2`,
      });
    }
  }, []);
  return (
    <>
      <Typography variant="body1">
        <FormattedMessage id="connections100KwInformation" />
      </Typography>
      {moreThanFiveConnections && (
        <Typography variant="body1">
          <FormattedMessage id="moreThanFiveConnectionsExplanation" />
        </Typography>
      )}
      <SelectControl
        isLoading={isLoading}
        isMulti
        required
        label="gridOperators"
        name={map("gridOperatorIds")}
        options={gridOperators}
      />
      <EanInputs />
    </>
  );
};

export default ConnectionDetailStep;
