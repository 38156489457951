import "bootstrap";
import { useLayoutEffect } from "react";
import "./App.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  RegistrationFormPage,
  RegistrationChangeFormPage,
  RegistrationCancelFormPage,
  LandingRegistrationPage,
  RegistrationSuccessPage,
} from "./components/RegistrationForm";
import { AuthCallback, ProtectedRoute, useAuthStore } from "./components/Auth";
import { OpenAPI } from "./openapi/edsn-webapi";
import CongestionInterestPage from "./components/CongestionInterest/CongestionInterestPage";
import CongestionInterestSuccessPage from "./components/CongestionInterest/CongestionInterestSuccessPage";
import Footer from "./components/Footer";
import { Grid } from "@mui/material";
import ReactGA from "react-ga4";

if (process.env.REACT_APP_UNIVERSAL_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(
    `${process.env.REACT_APP_UNIVERSAL_ANALYTICS_TRACKING_ID}`
  );
}

function App() {
  const access_token = useAuthStore((state) => state.access_token);

  useLayoutEffect(() => {
    OpenAPI.BASE = process.env.REACT_APP_EDSN_WEBAPI_BASE_URL as string;
  }, []);

  useLayoutEffect(() => {
    OpenAPI.TOKEN = access_token;
  }, [access_token]);

  return (
    <Grid sx={{ minHeight: "100%" }} container direction="column">
      <Grid item sx={{ marginBottom: "auto" }}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/registratieformulier" replace />}
            />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route
              path="/registratieformulier"
              element={
                <ProtectedRoute>
                  <LandingRegistrationPage />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/registratieformulier/create"
              element={
                <ProtectedRoute>
                  <RegistrationFormPage />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/registratieformulier/change"
              element={
                <ProtectedRoute>
                  <RegistrationChangeFormPage />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/registratieformulier/cancel"
              element={
                <ProtectedRoute>
                  <RegistrationCancelFormPage />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/registratieformulier/success"
              element={
                <ProtectedRoute>
                  <RegistrationSuccessPage />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/interesseformulier"
              element={<CongestionInterestPage />}
            ></Route>
            <Route
              path="/interesseformulier/succes"
              element={<CongestionInterestSuccessPage />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default App;
