import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useError } from "../../../hooks";
import defaultValidationRules from "./defaultValidationRules";

type DateControlProps = {
  required?: boolean;
  name: string;
  label?: string;
};

const DateControl = ({ required, name, label }: DateControlProps) => {
  const { control } = useFormContext();
  const intl = useIntl();
  const error = useError(name);
  const rules = defaultValidationRules({ required });

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <DatePicker
          label={intl.formatMessage({ id: label ?? name })}
          inputFormat="DD-MM-YYYY"
          {...field}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              required={required}
              helperText={
                error?.message
                  ? intl.formatMessage({ id: error.message as any })
                  : null
              }
              variant="standard"
              sx={{ mb: 2 }}
              fullWidth
            />
          )}
        />
      )}
    />
  );
};

export default DateControl;
