const ean13Validator = (value: string) => {
  if (value?.length !== 13 || !parseInt(value)) {
    return false;
  }

  const checksum = parseInt(value[12]);
  const sum = [...value.substring(0, 12)]
    .map((num) => parseInt(num))
    .reduce(
      (res, cur, index) => (index % 2 === 0 ? res + cur : res + 3 * cur),
      0
    );
  const digitToCheck = (10 - (sum % 10)) % 10;
  return digitToCheck === checksum;
};

export default ean13Validator;
