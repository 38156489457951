import { RegisterOptions } from "react-hook-form";

const defaultValidationRules = (
  registerOptions: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >
) => {
  return {
    ...registerOptions,
    required: registerOptions.required ? "requiredMessage" : false,
  };
};

export default defaultValidationRules;
