import { Grid, Link, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PageBody, PageHeader } from "../common";

const CongestionInterestSuccessPage = () => {
  return (
    <>
      <PageHeader maxWidth="md" title="congestionInterest" />
      <PageBody maxWidth="md">
        <Grid container direction="column">
          <Typography paragraph variant="body1">
            <FormattedMessage id="congestionInterestSuccessMessage" />
          </Typography>
          <Typography paragraph variant="body1">
            <FormattedMessage id="congestionInterestSuccessMessage2" />
          </Typography>
          <Link
            href={`${process.env.REACT_APP_PIE_BASE_URL}/themas/congestiemanagement`}
            variant="body1"
            underline="hover"
          >
            <FormattedMessage id="backToCongestionManagement" />
          </Link>
        </Grid>
      </PageBody>
    </>
  );
};

export default CongestionInterestSuccessPage;
