import create from "zustand";
import en from "../../translations/en.json";
import nl from "../../translations/nl.json";

const LanguageKey = "lang";

interface LocaleData {  
  flagCode: string;
  messages: Record<string, string>
}

export enum Language{
  "DUTCH"= "nl",
  "ENGLISH"= "en",
}

export const localeDataMap : Record<Language, LocaleData> = {
  [Language.DUTCH]: {
    flagCode: "NL",
    messages: nl
  },
  [Language.ENGLISH]: {
    flagCode: "GB",
    messages: {...nl, ...en}
  }
}

type LocalizationStoreState = {
    setLanguage: (language: Language) => void;
    language: Language;
};

const getLanguague = (): Language => {
  const lang = localStorage.getItem(LanguageKey) ?? Language.DUTCH; 
  return lang as Language
};

const useLocalizationStore = create<LocalizationStoreState>((set) => ({
  language: getLanguague(),
  setLanguage: (language: Language) => {
    set(() => ({
      language,     
    }));    
    localStorage.setItem(LanguageKey, language);
  },
}));

export default useLocalizationStore