import { useFormContext } from "react-hook-form";

const useError = (name: string) => {
  const {
    formState: { errors },
  } = useFormContext();

  let error = errors as any;
  const parts = name.split(".");
  while (parts.length > 0 && error) {
    error = error[parts.splice(0, 1)[0]];
  }

  return error;
};

export default useError;
