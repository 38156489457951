import { Controller, useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import defaultValidationRules from "./defaultValidationRules";
import { OverlayLoader } from "../Loaders";
import { useError } from "../../../hooks";

type SelectControlProps = {
  label?: string;
  name: string;
  isMulti?: boolean;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onChange?: (
    onChange: (...event: any[]) => void
  ) => (e: SelectChangeEvent<any>, child: React.ReactNode) => void;
  options: { value: any; label: string }[];
};

const SelectControl = ({
  label,
  name,
  options,
  isMulti,
  required,
  disabled,
  isLoading,
  onChange,
}: SelectControlProps) => {
  const { control } = useFormContext();
  const id = useRef(uuidv4());
  const error = useError(name);
  const rules = defaultValidationRules({ required });
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <FormControl
          sx={{ mb: 2 }}
          fullWidth
          required={required}
          variant="standard"
          error={!!error}
        >
          <InputLabel id={id.current}>
            <FormattedMessage id={label ?? name} />
          </InputLabel>
          <Select
            labelId={id.current}
            {...field}
            disabled={disabled}
            onChange={onChange ? onChange(field.onChange) : field.onChange}
            value={field.value ?? (isMulti ? [] : "")}
            multiple={isMulti}
            renderValue={(selected) =>
              isMulti
                ? selected
                    .map(
                      (m: string) => options.find((n) => n.value === m)?.label
                    )
                    .join(", ")
                : options.find((n) => n.value === selected)?.label
            }
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {isMulti ? (
                  <>
                    <Checkbox
                      checked={
                        (field.value &&
                          !!field.value.find((m: string) => m === value)) ||
                        false
                      }
                    />
                    <ListItemText primary={label} />
                  </>
                ) : (
                  label
                )}
              </MenuItem>
            ))}
          </Select>
          {error?.message && (
            <FormHelperText>
              <FormattedMessage id={error.message as any} />
            </FormHelperText>
          )}
          {isLoading && <OverlayLoader />}
        </FormControl>
      )}
    />
  );
};

export default SelectControl;
