import { SubmitHandler, UseFormProps } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutationWrapper } from "../../hooks";
import {
  CongestionInterestDto,
  CongestionInterestService,
} from "../../openapi/edsn-webapi";
import { Form, OverlayLoader, withFormContext } from "../common";
import CongestionInterestStepper from "./CongestionInterestStepper";
import { Link, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const CongestionInterestForm = () => {
  const navigate = useNavigate();
  const navigateToSuccess = () => navigate("/interesseformulier/succes");
  const { mutate, isLoading: isLoadingSubmit } = useMutationWrapper(
    ["congestionInterestSubmit"],
    (requestBody: CongestionInterestDto) =>
      CongestionInterestService.postApiCongestionInterestSubmit({
        requestBody,
      }),
    { onSuccess: navigateToSuccess }
  );

  const onSubmit: SubmitHandler<CongestionInterestDto> = (data) => {
    mutate(data);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        <FormattedMessage id="congestionInterestInformation" />
        <Link
          variant="subtitle2"
          underline="hover"
          target="_blank"
          href="https://www.eancodeboek.nl/"
        >
          https://www.eancodeboek.nl/
        </Link>
      </Typography>
      <CongestionInterestStepper />
      {isLoadingSubmit && <OverlayLoader />}
    </Form>
  );
};

export default withFormContext<
  UseFormProps<CongestionInterestDto>,
  CongestionInterestDto
>(CongestionInterestForm);
