import { Button, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PageBody, PageHeader } from "../common";

const LandingspageRegistration = () => {
  const navigate = useNavigate();
  const navigateToCreate = () => navigate("create");
  const navigateToChange = () => navigate("change");
  const navigateToCancel = () => navigate("cancel");

  return (
    <>
      <PageHeader maxWidth="sm" title="registrationTitle" />
      <PageBody maxWidth="sm">
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body2">
              <FormattedMessage id="landingRegister"></FormattedMessage>
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={navigateToCreate} variant="outlined" fullWidth>
              <FormattedMessage id="registerCreate" />
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={navigateToChange} variant="outlined" fullWidth>
              <FormattedMessage id="changeRegistrationTitle" />
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={navigateToCancel} variant="outlined" fullWidth>
              <FormattedMessage id="cancelRegistrationTitle" />
            </Button>
          </Grid>
        </Grid>
      </PageBody>
    </>
  );
};

export default LandingspageRegistration;
