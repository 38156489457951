import { SubmitHandler, useFormContext, UseFormProps } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  edsn_Marktrol,
  edsn_organizationalunit_edsn_wijzigingtype,
} from "../../../openapi/edsn-webapi";
import { OrganizationUnitDto } from "../../../openapi/edsn-webapi/models/OrganizationUnitDto";
import { safePropertyMap } from "../../../utils";
import { ean13Validator } from "../../../validators";
import { DateControl, Form, InputControl, withFormContext } from "../../common";

type OrganizationUnitFormProps = {
  onSubmit: SubmitHandler<OrganizationUnitDto>;
  renderAfter?: JSX.Element;
  eanDisabled: boolean;
  showFinancialProps?: boolean;
  marketRole: edsn_Marktrol;
};

const OrganizationUnitForm = ({
  onSubmit,
  renderAfter,
  eanDisabled,
  showFinancialProps,
  marketRole,
}: OrganizationUnitFormProps) => {
  const map = safePropertyMap<OrganizationUnitDto>();
  const intl = useIntl();
  const { watch } = useFormContext<OrganizationUnitDto>();
  const changeType = watch("changeType");
  const isDelete =
    changeType === edsn_organizationalunit_edsn_wijzigingtype.VERWIJDEREN;
  const genericTooltip = intl.formatMessage(
    { id: "tooltipEan" },
    {
      marketRole: intl.formatMessage({ id: `edsn_Marktrol.${marketRole}` }),
    }
  );

  return (
    <Form onSubmit={onSubmit}>
      <InputControl required name={map("name")} label="nameMarket" />
      <InputControl
        required
        disabled={eanDisabled}
        name={map("ean13")}
        label="eanCodeMarket"
        tooltip={genericTooltip}
        tooltipTranslated={!!marketRole}
        validate={ean13Validator}
      />
      {showFinancialProps && !isDelete && (
        <>
          <InputControl
            required
            name={map("vatNumber")}
            label="vatNumb"
            minLength={1}
            maxLength={14}
          />
          <InputControl
            required
            name={map("bankAccount")}
            label="accountNumb"
            tooltip="tooltipAdminPartyAccountNumber"
            pattern={{
              value: /^[A-Z]{2}(\d{2}[A-Z]{1,4})?\d{10,27}$/,
              message: intl.formatMessage({ id: "bankAccountIncorrect" }),
            }}
          />
        </>
      )}
      {isDelete && <DateControl name={map("endDate")} required />}
      {renderAfter}
    </Form>
  );
};

export default withFormContext<
  UseFormProps<OrganizationUnitDto> & OrganizationUnitFormProps,
  OrganizationUnitDto
>(OrganizationUnitForm);
