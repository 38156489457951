import { Box, SelectChangeEvent } from "@mui/material";
import { RegistrationCreateDto } from "../../../openapi/edsn-webapi";
import { safePropertyMap } from "../../../utils";
import MarketroleSelectControl from "../MarketroleSelectControl";
import { useFormContext } from "react-hook-form";
import useRegistration from "../useRegistration";

const MarketRoleStep = () => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const { reset } = useFormContext();
  const setPermissionDocumentGas = useRegistration(
    (state) => state.setPermissionDocumentGas
  );
  const setPermissionDocumentelectricity = useRegistration(
    (state) => state.setPermissionDocumentelectricity
  );
  const isUpdate = useRegistration((state) => state.isUpdate);
  const onChange = () => (e: SelectChangeEvent<any>) => {
    reset({ marketRole: e.target.value });
    setPermissionDocumentGas(undefined);
    setPermissionDocumentelectricity(undefined);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <MarketroleSelectControl
        required
        disabled={isUpdate}
        name={map("marketRole")}
        onChange={onChange}
      />
    </Box>
  );
};

export default MarketRoleStep;
