import {
  AccordionDetails,
  Alert,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  Link,
  Box,
} from "@mui/material";
import { useFormContext, ValidateResult } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { DateControl, InputControl, Tooltip } from "../common";
import CheckboxControl from "../common/Form/CheckboxControl";
import useRegistration from "./useRegistration";

const ProductSelectControl = () => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<RegistrationCreateDto>();
  const showElec = watch("electricity") ?? false;
  const showGas = watch("gas") ?? false;
  const marketRole = watch("marketRole")!;
  const elecPermitRequired = watch("kvElectricitySupplierPermitRequired")!;
  const gasPermitRequired = watch("kvGasSupplierPermitRequired")!;
  const setPermissionDocumentGas = useRegistration(
    (state) => state.setPermissionDocumentGas
  );
  const setPermissionDocumentelectricity = useRegistration(
    (state) => state.setPermissionDocumentelectricity
  );

  const showWaiver =
    marketRole === edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM;
  const isSupplier = marketRole === edsn_Marktrol.LVLEVERANCIER;
  const showPermit = isSupplier && (elecPermitRequired || gasPermitRequired);
  const showRecogProg =
    marketRole === edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE;

  const elecTooltipMap: { [name: string]: string } = {
    [edsn_Marktrol.LVLEVERANCIER]: "tooltipLvProductElec",
    [edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE]: "tooltipPvProductElec",
    [edsn_Marktrol.MVMEETVERANTWOORDELIJKE]: "tooltipMvProductElec",
  };
  const elecTooltip = marketRole && elecTooltipMap[marketRole];

  const gasTooltipMap: { [name: string]: string } = {
    [edsn_Marktrol.LVLEVERANCIER]: "tooltipLvProductGas",
    [edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE]: "tooltipPvProductGas",
    [edsn_Marktrol.MVMEETVERANTWOORDELIJKE]: "tooltipMvProductGas",
  };
  const gasTooltip = marketRole && gasTooltipMap[marketRole];

  const productTooltip =
    marketRole === edsn_Marktrol.LVLEVERANCIER
      ? "tooltipLvProduct"
      : "tooltipOtherProduct";

  const onChangeElec = (checked: boolean) => {
    if (!checked) {
      setValue("kvElectricitySupplierPermitNotRequired", false);
      setValue("kvElectricitySupplierPermitRequired", false);
      setValue("largeConsumerElectricity", false);
      setPermissionDocumentelectricity(undefined);
    }
  };

  const onChangeGas = (checked: boolean) => {
    if (!checked) {
      setValue("kvGasSupplierPermitNotRequired", false);
      setValue("kvGasSupplierPermitRequired", false);
      setValue("largeConsumerGas", false);
      setPermissionDocumentGas(undefined);
    }
  };

  const atleastOneProductRequired = (value: boolean): ValidateResult => {
    return value || getValues("gas") || "atleastOneProductRequired";
  };

  const atleastOneElectricityProductRequired = (
    value: boolean
  ): ValidateResult => {
    return (
      !isSupplier ||
      !value ||
      getValues([
        "kvElectricitySupplierPermitNotRequired",
        "kvElectricitySupplierPermitRequired",
        "largeConsumerElectricity",
      ]).some((m) => m) ||
      "atleastOneElectricityProductRequired"
    );
  };

  const atleastOneGasProductRequired = (value: boolean): ValidateResult => {
    return (
      !isSupplier ||
      !value ||
      getValues([
        "kvGasSupplierPermitNotRequired",
        "kvGasSupplierPermitRequired",
        "largeConsumerGas",
      ]).some((m) => m) ||
      "atleastOneGasProductRequired"
    );
  };

  return (
    <>
      <Typography variant="h6" color="secondary" display="inline">
        <FormattedMessage id={"products"} />
        <Tooltip title={productTooltip} />
      </Typography>
      <Accordion expanded={showElec && isSupplier}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <CheckboxControl
            showFormHelperTextOnError={false}
            tooltip={elecTooltip}
            name={map("electricity")}
            onChange={onChangeElec}
            validate={{
              atleastOneProductRequired: atleastOneProductRequired,
              atleastOneElectricityProductRequired:
                atleastOneElectricityProductRequired,
            }}
          />
        </AccordionSummary>
        {isSupplier && (
          <AccordionDetails>
            <Grid container direction={"column"}>
              <Grid item>
                <CheckboxControl
                  label="kvSupplierPermitRequired"
                  name={map("kvElectricitySupplierPermitRequired")}
                />
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <CheckboxControl
                    label="kvSupplierPermitNotRequired"
                    name={map("kvElectricitySupplierPermitNotRequired")}
                    tooltip="tooltipLvProductKv"
                  />
                  <Link
                    variant="subtitle2"
                    underline="hover"
                    target="_blank"
                    href="https://www.acm.nl/nl/onderwerpen/energie/energiebedrijven/vergunningen/wanneer-is-geen-energievergunning-nodig"
                  >
                    <FormattedMessage id="textAcm" />
                  </Link>
                </Box>
              </Grid>
              <Grid item>
                <CheckboxControl
                  label="largeConsumerElectricity"
                  name={map("largeConsumerElectricity")}
                />
              </Grid>
              {errors["electricity"]?.type ===
                "atleastOneElectricityProductRequired" && (
                <Grid item>
                  <Alert severity="error">
                    <FormattedMessage id={errors["electricity"].message} />
                  </Alert>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
      <Accordion expanded={showGas && isSupplier}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <CheckboxControl
            showFormHelperTextOnError={false}
            tooltip={gasTooltip}
            name={map("gas")}
            onChange={onChangeGas}
            validate={{
              atleastOneGasProductRequired: atleastOneGasProductRequired,
            }}
          />
        </AccordionSummary>
        {isSupplier && (
          <AccordionDetails>
            <Grid container direction={"column"}>
              <Grid item>
                <CheckboxControl
                  label="kvSupplierPermitRequired"
                  name={map("kvGasSupplierPermitRequired")}
                />
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <CheckboxControl
                    label="kvSupplierPermitNotRequired"
                    name={map("kvGasSupplierPermitNotRequired")}
                    tooltip="tooltipLvProductKv"
                  />
                  <Link
                    variant="subtitle2"
                    underline="hover"
                    target="_blank"
                    href="https://www.acm.nl/nl/onderwerpen/energie/energiebedrijven/vergunningen/wanneer-is-geen-energievergunning-nodig"
                  >
                    <FormattedMessage id="textAcm" />
                  </Link>
                </Box>
              </Grid>
              <Grid item>
                <CheckboxControl
                  label="largeConsumerGas"
                  name={map("largeConsumerGas")}
                />
              </Grid>
              {errors["gas"]?.type === "atleastOneGasProductRequired" && (
                <Grid item>
                  <Alert severity="error">
                    <FormattedMessage id={errors["gas"].message} />
                  </Alert>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
      {errors["electricity"]?.type === "atleastOneProductRequired" && (
        <Alert severity="error" sx={{ my: 2 }}>
          <FormattedMessage id={errors["electricity"].message} />
        </Alert>
      )}

      {showWaiver && (showElec || showGas) && (
        <Typography variant="h6" color="secondary">
          <FormattedMessage id="exemptions" />
          <Tooltip title="tooltipExemptions" />
        </Typography>
      )}
      {showElec && showWaiver && (
        <>
          <Typography variant="h6" color="secondary">
            <FormattedMessage id="electricity" />
          </Typography>
          <InputControl name={map("exemptionElec")} label="ontheffingshouder" />
          <InputControl name={map("referenceElec")} label="kenmerkOntheffing" />
          <DateControl
            name={map("exemptionEndDateElectricity")}
            label="endDate"
          />
        </>
      )}
      {showGas && showWaiver && (
        <>
          <Typography variant="h6" color="secondary">
            <FormattedMessage id="gas" />
          </Typography>
          <InputControl name={map("exemptionGas")} label="ontheffingshouder" />
          <InputControl name={map("referenceGas")} label="kenmerkOntheffing" />
          <DateControl name={map("exemptionEndDateGas")} label="endDate" />
        </>
      )}
      {showPermit && (
        <>
          <Typography variant="h6" color="secondary">
            <FormattedMessage id="supplyLicense" />
            <Tooltip title="tooltipLv" />
          </Typography>
          <InputControl name={map("licenseHolder")} label="licenseHolder" />
          {elecPermitRequired && (
            <>
              <InputControl
                name={map("referenceElec")}
                label="kenmerkVergunningElek"
              />
              <DateControl
                name={map("recognitionElecStartDate")}
                label="startDateSupplierElek"
              />
            </>
          )}
          {gasPermitRequired && (
            <>
              <InputControl
                name={map("referenceGas")}
                label="kenmerkVergunningGas"
              />
              <DateControl
                name={map("recognitionGasStartDate")}
                label="startDateSupplierGas"
              />
            </>
          )}
        </>
      )}
      {showRecogProg && (
        <>
          {showElec && (
            <>
              <Typography variant="h6" color="secondary">
                <FormattedMessage id="recogElec" />
              </Typography>
              <InputControl name={map("recognitionElec")} label="namePvElec" />
              <DateControl
                name={map("recognitionElecStartDate")}
                label="startdateRecog"
              />
            </>
          )}
          {showGas && (
            <>
              <Typography variant="h6" color="secondary">
                <FormattedMessage id="recogGas" />
              </Typography>
              <InputControl name={map("recognitionGas")} label="namePvGas" />
              <DateControl
                name={map("recognitionGasStartDate")}
                label="startdateRecog"
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProductSelectControl;
