import PreferredPartyElectricityControl from "./PreferredPartyElectricityControl";
import PreferredPartyGasControl from "./PreferredPartyGasControl";
import {
  RegistrationCreateDto,
  edsn_Marktrol,
} from "../../openapi/edsn-webapi";
import { useFormContext } from "react-hook-form";

const CreatePreferredPartyControl = () => {
  const { watch } = useFormContext<RegistrationCreateDto>();
  const isLv = watch("marketRole")! === edsn_Marktrol.LVLEVERANCIER;

  const electricity = watch("electricity") ?? false;
  const kvElectricitySupplierPermitRequired =
    watch("kvElectricitySupplierPermitRequired") ?? false;
  const kvElectricitySupplierPermitNotRequired =
    watch("kvElectricitySupplierPermitNotRequired") ?? false;
  const showElec = isLv
    ? kvElectricitySupplierPermitRequired ||
      kvElectricitySupplierPermitNotRequired
    : electricity;

  const gas = watch("gas") ?? false;
  const kvGasSupplierPermitRequired =
    watch("kvGasSupplierPermitRequired") ?? false;
  const kvGasSupplierPermitNotRequired =
    watch("kvGasSupplierPermitNotRequired") ?? false;
  const showGas = isLv
    ? kvGasSupplierPermitRequired || kvGasSupplierPermitNotRequired
    : gas;

  return (
    <>
      {showElec && <PreferredPartyElectricityControl />}
      {showGas && <PreferredPartyGasControl />}
    </>
  );
};

export default CreatePreferredPartyControl;
