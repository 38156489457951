import { Grid, Link, Typography } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{ p: 4, bgcolor: "secondary.main" }}
        className="page-footer"
      >
        <Grid item>
          <Grid container rowGap={3} columnGap={10}>
            <Link
              href="https://www.edsn.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/EDSN_tagline_Wit_x56.png`}
                alt="EDSN"
              />
            </Link>
            <Link
              href="https://www.westlandinfra.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/WestlandInfraWhite.svg`}
                alt="EDSN"
              />
            </Link>
            <Link
              href="https://www.rendogroep.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/RendoWhite.svg`}
                alt="EDSN"
              />
            </Link>
            <Link
              href="https://www.stedin.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/StedinWhite.svg`}
                alt="EDSN"
              />
            </Link>
            <Link
              href="https://coteqnetbeheer.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/CoteqWhite.svg`}
                alt="EDSN"
              />
            </Link>
            <Link
              href="https://www.liander.nl/consument"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/LianderWhite.svg`}
                alt="EDSN"
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        sx={{ p: 2, bgcolor: "secondary.main" }}
      >
        <Grid item>
          <Grid container gap={2}>
            <Typography variant="body2" color="secondary.contrastText">
              Copyright © 2021
            </Typography>
            <Link
              variant="body2"
              underline="hover"
              color="secondary.contrastText"
              href={`${process.env.REACT_APP_PIE_BASE_URL}/privacy`}
            >
              Privacy
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
