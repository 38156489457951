import { useLocation } from "react-router-dom";
import Login from "./Login";
import useAuthStore from "./useAuthStore";

interface ProtectedRouteProps {
  state?: string;
  children: JSX.Element;
}

const ProtectedRoute = ({ children, state }: ProtectedRouteProps) => {
  const location = useLocation();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  return isAuthenticated() ? (
    children
  ) : (
    <Login
      redirect={`${location.pathname}${encodeURIComponent(location.search)}`}
      state={state}
    />
  );
};

export default ProtectedRoute;
