import { useEffect } from "react";
import { Path, useFormContext } from "react-hook-form";
import { RegistrationCreateDto } from "../../openapi/edsn-webapi";
import { YesNoControl } from "../common";
import ContactControl from "./ContactControl";
import ContactWithPhonePropType from "./ContactWithPhonePropType";

type OptionalContactProps = {
  name: ContactWithPhonePropType;
  nameBool: Path<RegistrationCreateDto>;
  label?: string;
};

const OptionalContactControl = ({
  name,
  nameBool,
  label,
}: OptionalContactProps) => {
  const { watch, setValue } = useFormContext<RegistrationCreateDto>();
  const showContact = watch(nameBool) === "*true*";

  useEffect(() => {
    if (!showContact) {
      setValue(name, undefined);
    }
  }, [showContact]);

  return (
    <>
      <YesNoControl name={nameBool} label={label} defaultValue={false} />
      {showContact && <ContactControl fieldname={name} />}
    </>
  );
};

export default OptionalContactControl;
