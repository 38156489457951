import { isValidPhoneNumber } from "libphonenumber-js";
import { ValidateResult } from "react-hook-form";

const phoneValidator = (value: string): ValidateResult => {
  if (
    value.match(/^\+[0-9]\d{1,15}$/) &&
    isValidPhoneNumber(value, "NL") === true
  ) {
    return true;
  }

  return "phoneNumberHelper";
};

export default phoneValidator;
