import { Alert, Button, Typography } from "@mui/material";
import { InputControl, OverlayLoader } from "../common";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MprService,
  PreferredPartyEanValidationDto,
  PreferredPartyEanValidationResponsDto,
  RegistrationCreateDto,
  edsn_Marktrol,
} from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { ValidateResult, useFormContext } from "react-hook-form";
import CheckboxControl from "../common/Form/CheckboxControl";
import { ean13Validator } from "../../validators";
import useRegistration from "./useRegistration";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { useMutationWrapper } from "../../hooks";
import { useState } from "react";

type PreferredPartyElectricityControlProps = {
  showLvTooltip?: boolean;
  required?: boolean;
};

const PreferredPartyGasControl = ({
  showLvTooltip,
  required,
}: PreferredPartyElectricityControlProps) => {
  const map = safePropertyMap<RegistrationCreateDto>();
  const { watch, getValues } = useFormContext<RegistrationCreateDto>();
  const intl = useIntl();
  const marketRole = watch("marketRole")!;

  const marketRoleToCheck =
    marketRole === edsn_Marktrol.LVLEVERANCIER
      ? edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE
      : edsn_Marktrol.LVLEVERANCIER;

  const showPvActingAsLvEan =
    marketRole === edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE;

  const [preferredPartyGasState, setPreferredPartyGasState] =
    useState<PreferredPartyEanValidationResponsDto>();

  const [preferredPartySupplierGasState, setPreferredPartySupplierGasState] =
    useState<PreferredPartyEanValidationResponsDto>();

  const { mutateAsync, isLoading } = useMutationWrapper(
    ["postApiMprValidatePreferredParty"],
    (requestBody: PreferredPartyEanValidationDto) =>
      MprService.postApiMprValidatePreferredParty({ requestBody })
  );

  const setPermissionDocumentGas = useRegistration(
    (state) => state.setPermissionDocumentGas
  );
  const permissionDocumentGas = useRegistration(
    (state) => state.permissionDocumentGas
  );

  const isUpdate = useRegistration((state) => state.isUpdate);

  const validatePermissonsDocumentGas = (val: string): ValidateResult => {
    var hasEan = !!getValues().preferredPartyGasEan;
    if (!required) {
      if (!hasEan) {
        return true;
      }
      if (!val) {
        return "requiredMessage";
      }
    }
    return !!permissionDocumentGas || isUpdate || "fileRequired";
  };

  const validateEan = async (
    ean13Code: string,
    setter: (result: PreferredPartyEanValidationResponsDto) => void
  ) => {
    if (!ean13Code && !required) {
      return true;
    }

    const validEan = ean13Validator(ean13Code);

    if (!validEan) {
      setter({ isValid: false, name: "__invalid__" });
      return "invalidEan";
    }

    const result = await mutateAsync({
      ean13Code,
      marketRole: marketRoleToCheck,
    });
    setter(result);

    if (!result.isValid) {
      return "preferredPartyEanNotFound";
    }

    return true;
  };

  return (
    <>
      <Typography variant="h6" color="secondary">
        <FormattedMessage id="preferredPartyGas" />
      </Typography>
      {showLvTooltip && (
        <Alert severity="info">
          <FormattedMessage id="preferredPartLvInfoMessage" />
        </Alert>
      )}
      <InputControl
        maxLength={13}
        name={map("preferredPartyGasEan")}
        label="preferredPartyGasEan"
        required={required}
        helperText={
          preferredPartyGasState
            ? preferredPartyGasState.name === "__invalid__"
              ? intl.formatMessage({ id: "invalidEan" })
              : preferredPartyGasState.isValid
              ? preferredPartyGasState.name!
              : intl.formatMessage({ id: "preferredPartyEanNotFound" })
            : undefined
        }
        endAdornment={isLoading ? <OverlayLoader /> : undefined}
        validate={(ean13Code: string) =>
          validateEan(ean13Code, setPreferredPartyGasState)
        }
        onBlur={(e) => validateEan(e.target.value, setPreferredPartyGasState)}
      />

      <CheckboxControl
        name={map("preferredPartyGasContractIsSigned")}
        label="preferredPartyContractIsSigned"
        required={required}
        validate={validatePermissonsDocumentGas}
      />
      <Button
        sx={{ width: "fit-content", mt: 1, mb: 2 }}
        variant="outlined"
        startIcon={<CloudUpload />}
        component="label"
      >
        {permissionDocumentGas ? (
          permissionDocumentGas?.name
        ) : (
          <FormattedMessage id="uploadFile" />
        )}
        <input
          onChange={(event) =>
            setPermissionDocumentGas(
              event.target.files?.length ? event.target.files[0] : undefined
            )
          }
          type="file"
          hidden
        />
      </Button>
      {showPvActingAsLvEan && (
        <InputControl
          maxLength={13}
          name={map("pvPreferredPartyGasLvEan")}
          label="lvEan"
          required={required}
          helperText={
            preferredPartySupplierGasState
              ? preferredPartySupplierGasState.name === "__invalid__"
                ? intl.formatMessage({ id: "invalidEan" })
                : preferredPartySupplierGasState.isValid
                ? preferredPartySupplierGasState.name!
                : intl.formatMessage({ id: "preferredPartyEanNotFound" })
              : undefined
          }
          endAdornment={isLoading ? <OverlayLoader /> : undefined}
          validate={(ean13Code: string) =>
            validateEan(ean13Code, setPreferredPartySupplierGasState)
          }
          onBlur={(e) =>
            validateEan(e.target.value, setPreferredPartySupplierGasState)
          }
        />
      )}
    </>
  );
};

export default PreferredPartyGasControl;
