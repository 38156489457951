import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import {
  CongestionInterestDto,
  ContactPersonForCongestion,
} from "../../openapi/edsn-webapi";
import { safePropertyMap } from "../../utils";
import { InputControl, RadioControl } from "../common";

const ContactPersonDetails = () => {
  const map = safePropertyMap<CongestionInterestDto>();
  const intl = useIntl();

  const Name_Function_Phone_Email = () => (
    <>
      <InputControl required label="name" name={map("contactName")} />
      <InputControl required label="function" name={map("contactFunction")} />
      <InputControl
        required
        label="phoneNumber"
        name={map("contactTelephone")}
      />
      <InputControl
        required
        label="email"
        pattern={{
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: intl.formatMessage({ id: "invalidEmailAddress" }),
        }}
        type="email"
        name={map("contactEmailAddress")}
      />
    </>
  );

  const Company_Name_Function_Phone_Email = () => (
    <>
      <InputControl
        required
        label="accountName"
        name={map("contactCompanyName")}
      />
      <Name_Function_Phone_Email />
    </>
  );

  const optionExtraFields: Record<string, React.ReactElement> = {
    [ContactPersonForCongestion.SOMEONE_IN_THE_COMPANY]: (
      <Box sx={{ ml: 4 }}>
        <Name_Function_Phone_Email />
      </Box>
    ),
    [ContactPersonForCongestion.SUPPLIER]: (
      <Box sx={{ ml: 4 }}>
        <Company_Name_Function_Phone_Email />
      </Box>
    ),
    [ContactPersonForCongestion.CONGESTION_SERVICE_PROVIDER]: (
      <Box sx={{ ml: 4 }}>
        <Company_Name_Function_Phone_Email />
      </Box>
    ),
    [ContactPersonForCongestion.OTHER]: (
      <Box sx={{ ml: 4 }}>
        <Company_Name_Function_Phone_Email />
        <InputControl
          required
          label="explenation"
          name={map("contactPersonForCongestionOther")}
        />
      </Box>
    ),
  };

  const contactOptions = Object.values(ContactPersonForCongestion).map((m) => ({
    value: m,
    label: `contactPersonForCongestion.${m}`,
    selectedRenderBelow: optionExtraFields[m],
  }));

  return (
    <>
      <RadioControl
        required
        name={map("contactPersonForCongestion")}
        options={contactOptions}
      />
    </>
  );
};

export default ContactPersonDetails;
