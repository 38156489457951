import { Alert, Grid, Typography } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  edsn_Marktrol,
  RegistrationCreateDto,
} from "../../../openapi/edsn-webapi";
import { Tooltip } from "../../common";
import OrganizationButtonGrid from "./OrganizationButtonGrid";
import OrganizationUnitCard from "./OrganizationUnitCard";

type OrganizationUnitsProps = {
  isCreate?: boolean;
};

const OrganizationUnits = ({ isCreate }: OrganizationUnitsProps) => {
  const { watch, control } = useFormContext<RegistrationCreateDto>();
  const organizationUnits = watch("organizationUnits") ?? [];
  const marketRole = watch("marketRole")!;
  const {
    fieldState: { error },
  } = useController({
    name: "organizationUnits",
    control,
    rules: { required: true, minLength: 1 },
  });

  const adminPartyTooltipMap: { [name: string]: string } = {
    [edsn_Marktrol.LVLEVERANCIER]: "tooltipLVAdminParty",
    [edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE]: "tooltipPvAdminParty",
  };
  const adminPartyTooltip = marketRole && adminPartyTooltipMap[marketRole];

  return (
    <>
      <Typography variant="h6" color="secondary">
        <FormattedMessage id="adminParties" />
        <Tooltip title={adminPartyTooltip} />
      </Typography>
      <OrganizationButtonGrid isCreate={isCreate} />
      <Grid container spacing={2} sx={{ mb: 1 }}>
        {organizationUnits.map((organizationUnit, index) => (
          <Grid item key={index} xs={6}>
            <OrganizationUnitCard
              index={index}
              {...organizationUnit}
              isCreate={isCreate}
            />
          </Grid>
        ))}
      </Grid>
      {error && (
        <Alert sx={{ mt: 2, mb: 1 }} severity="error">
          <FormattedMessage id="atleastOneOrganizationUnitRequired" />
        </Alert>
      )}
    </>
  );
};

export default OrganizationUnits;
