import { Box, InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import { ChangeEvent, InputHTMLAttributes } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import defaultValidationRules from "./defaultValidationRules";
import Tooltip from "../Tooltip/Tooltip";
import { useError } from "../../../hooks";

type InputControlProps = {
  label?: string;
  name: string;
  helperText?: string;
  placeholder?: string;
  tooltip?: string;
  tooltipTranslated?: boolean;
  endAdornment?: React.ReactElement;
  noLabel?: boolean;
  sx?: SxProps<Theme>;
  onChange?: (
    onChange: (...event: any[]) => void
  ) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const InputControl = ({
  disabled,
  helperText,
  placeholder,
  tooltip,
  tooltipTranslated,
  onChange,
  endAdornment,
  noLabel,
  sx = {},
  ...props
}: InputControlProps &
  RegisterOptions &
  Omit<InputHTMLAttributes<HTMLInputElement>, "pattern" | "onChange">) => {
  const { label, name } = props;
  const error = useError(name);
  const { control } = useFormContext();
  const intl = useIntl();
  const rules = defaultValidationRules(props);
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <Box sx={{ width: props.width }}>
          <TextField
            required={props.required}
            {...field}
            onChange={onChange ? onChange(field.onChange) : field.onChange}
            helperText={
              !!error?.message
                ? intl.formatMessage({
                    id: error.message as any,
                  })
                : helperText
                ? intl.formatMessage({
                    id: helperText,
                  })
                : null
            }
            disabled={disabled}
            placeholder={placeholder}
            InputLabelProps={{
              hidden: noLabel,
            }}
            inputProps={{
              readOnly: props.readOnly,
            }}
            InputProps={{
              endAdornment: (
                <>
                  {endAdornment}
                  {tooltip && (
                    <InputAdornment position="end">
                      <Tooltip
                        title={tooltip}
                        titleTranslated={tooltipTranslated}
                      />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            type={props.type}
            error={!!error}
            value={field.value ?? ""}
            fullWidth
            label={intl.formatMessage({ id: label ?? name })}
            variant="standard"
            sx={{ mb: 2, ...sx }}
          />
        </Box>
      )}
    />
  );
};

export default InputControl;
