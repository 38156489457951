import React, { FormEvent } from "react";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
interface FormProps<T extends FieldValues> {
  children: React.ReactNode;
  onSubmit: SubmitHandler<T>;
}

const Form = <T extends FieldValues>({ children, onSubmit }: FormProps<T>) => {
  const { handleSubmit } = useFormContext<T>();
  const submitWrapper: SubmitHandler<T> = (data) =>
    onSubmit(
      JSON.parse(
        JSON.stringify(data)
          .replaceAll('"*true*"', "true")
          .replaceAll('"*false*"', "false")
      )
    );

  const onSubmitWrapper = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(submitWrapper)();
  };
  return (
    <form onSubmit={onSubmitWrapper} noValidate>
      {children}
    </form>
  );
};

export default Form;
