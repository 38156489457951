import { SelectControl } from "../common";
import { useIntl } from "react-intl";
import { edsn_CSPproduct } from "../../openapi/edsn-webapi";

type ProductSelectCspControlProps = {
  label?: string;
  name: string;
  required?: boolean;
};

const ProductCspSelectControl = ({
  label = "product",
  name,
  required,
}: ProductSelectCspControlProps) => {
  const intl = useIntl();
  const options = Object.values(edsn_CSPproduct).map((value) => ({
    value,
    label: intl.formatMessage({ id: `edsn_CSPproduct.${value}` }),
  }));

  return (
    <SelectControl
      name={name}
      label={label}
      options={options}
      required={required}
      isMulti
    />
  );
};

export default ProductCspSelectControl;
