import { ComponentType } from "react";
import {
  FieldValues,
  FormProvider,
  useForm,
  UseFormProps,
} from "react-hook-form";

const withFormContext =
  <P, TFormValues extends FieldValues>(
    Component: ComponentType<P & UseFormProps<TFormValues, any>>
  ) =>
  (props: P & UseFormProps<TFormValues>) => {
    const methods = useForm<TFormValues>(props);
    return (
      <FormProvider {...methods}>
        <Component {...props} />
      </FormProvider>
    );
  };

export default withFormContext;
