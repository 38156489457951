import { YesNoControl } from "../common";
import { safePropertyMap } from "../../utils";
import { CongestionInterestDto } from "../../openapi/edsn-webapi";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { Alert, Link, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

const ConnectionInfoStep = () => {
  const map = safePropertyMap<CongestionInterestDto>();
  const { watch } = useFormContext<CongestionInterestDto>();
  const noConnectionsWithMoreThan100Kw =
    (watch("connectionsWithMoreThan100Kw") as unknown as string) === "*false*";
  useEffect(() => {
    if (process.env.REACT_APP_UNIVERSAL_ANALYTICS_TRACKING_ID) {
      ReactGA.send({
        hitType: "pageview",
        page: `${window.location.pathname}${window.location.search}/step1`,
      });
    }
  }, []);
  return (
    <>
      <YesNoControl
        required
        name={map("moreThanFiveConnections")}
        yesLabel="moreThanFiveConnections.yes"
        noLabel="moreThanFiveConnections.no"
        row={false}
      />
      <YesNoControl
        validate={(m) => (m === "*false*" ? false : true)}
        name={map("connectionsWithMoreThan100Kw")}
        required
      />
      <Typography variant="body1" sx={{ mt: 2 }}>
        <FormattedMessage id="congestionInterestNextStepInfo"></FormattedMessage>
      </Typography>
      {noConnectionsWithMoreThan100Kw && (
        <Alert severity="warning">
          <FormattedMessage
            id="noConnectionsWithMoreThan100Kw"
            values={{
              here: (
                <Link
                  href="https://www.tennet.eu/nl/csp-register"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="here" />
                </Link>
              ),
              readMore: (
                <Link
                  href={`${process.env.REACT_APP_PIE_BASE_URL}/themas/congestiemanagement`}
                >
                  <FormattedMessage id="readMoreAboutCsp" />
                </Link>
              ),
            }}
          />
        </Alert>
      )}
    </>
  );
};

export default ConnectionInfoStep;
